import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Select from 'react-select';
import MyDateTimePicker from "../mydatetimepicker";

const now = new Date();
let date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}
export default class QuickBillAction extends Component {
    constructor(props) {
        super(props);
        console.log('.......')
    }
    state = {patientId: '', companyId: '', visitType: '', allowSave: '', treatmentDateTime: '',  fullname: '', idNumber: '', gender: '', mobile: '', age: '', dateOfBirth: '', number: '', billCategory: '', billCategorys: [], clusterCompanys: [], users: [], currencys: [], institutions: [], patientInsurances: [], services: [], serviceId: '', patientInsuranceId: '', specialContractId: '', currencyId: '' , rate: 0, consultantId: '', billType: '', options: [], consultantIdError: '', billCategoryError: '', companyIdError: '', currencyIdError: '', patientInsuranceIdError: '', specialContractIdError: '' , treatmentDateTimeError: '', unitCostError: '', unitCost: 0}

 
    getQuickBill(patientId) {
        this.props.setLoader(true)
        this.setState({patientId: '', companyId: '', visitType: '', allowSave: '', treatmentDateTime: '',  fullname: '', idNumber: '', gender: '', mobile: '', age: '', dateOfBirth: '', number: '', billCategory: '', billCategorys: [], clusterCompanys: [], users: [], currencys: [], institutions: [], patientInsurances: [], services: [], serviceId: '', patientInsuranceId: '', specialContractId: '', currencyId: '', rate: 0, consultantId: '', billType: '' , options: [], consultantIdError: '', billCategoryError: '', companyIdError: '', currencyIdError: '', patientInsuranceIdError: '', specialContractIdError: '', treatmentDateTimeError: '', unitCostError: '', unitCost: 0})
        axios.get(localStorage.getItem('host') + '/patient/quickbillaction/' + patientId + '/' + localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({patientId: res.data.patientId, companyId: res.data.companyId, allowSave: res.data.allowSave, treatmentDateTime: res.data.treatmentDateTime,  fullname: res.data.fullname, idNumber: res.data.idNumber, gender: res.data.gender, mobile: res.data.mobile, age: res.data.age, dateOfBirth: res.data.dateOfBirth, number: res.data.number, billCategorys: res.data.billCategorys, clusterCompanys: res.data.clusterCompanys, users: res.data.users, currencys: res.data.currencys, institutions: res.data.institutions, patientInsurances: res.data.patientInsurances,});
               
                document.getElementById('quickBillAction').style = 'display:block'
                window.scrollTo(0, 0);
                this.doValidate();
                this.props.setLoader(false)
            }, err => {
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )

    }
    setQuickBill(name, value) {
        switch (name) {
            case "companyId":
                for (var i in this.state.clusterCompanys) {
                    if (Number(this.state.clusterCompanys[i].id) === Number(value)) {
                        let companyId = this.state.clusterCompanys[i].id;
                        let users = this.state.clusterCompanys[i].users;
                        let patientInsurances = this.state.clusterCompanys[i].patientInsurances;
                        let institutions = this.state.clusterCompanys[i].institutions;
                        let currencys = this.state.clusterCompanys[i].currencys;
                        let allowSave = this.state.clusterCompanys[i].allowSave
                        console.log('Allow Save'+allowSave)
                        this.setState({error: '', companyId: companyId, users: users, patientInsurances: patientInsurances, institutions: institutions, currencys: currencys, patientInsuranceId: 0, specialContractId: 0, institutionId: 0, currencyId: 0, rate: 0, billCategory: '', consultantId: 0, allowSave: allowSave})
                    }
                }
                break;
            case "billCategory":
                let currencyId = 0
                let rate = 0
                if(value === "CASH" && this.state.currencys.length === 1)
                    {
                     currencyId = this.state.currencys[0].id
                     rate = this.state.currencys[0].rate
                    }
                this.setState({ billCategory: value, patientInsuranceId: 0, specialContractId: 0, institutionId: 0, currencyId: currencyId, rate: rate })
                setTimeout(() => { this.getServices(0, rate) }, 1000)
                break;
            case "consultantId":
                this.setState({ consultantId: value })
                break;
            case "treatmentDateTime":
                this.setState({ treatmentDateTime: value })
                break;
            case "patientInsuranceId":
                for (let j in this.state.patientInsurances) {
                    if (this.state.patientInsurances[j].id === Number(value)) {
                        let patientInsuranceId = this.state.patientInsurances[j].id;
                        let institutionId = this.state.patientInsurances[j].institutionId;
                        let currencyId = this.state.patientInsurances[j].currencyId;
                        let rate = this.state.patientInsurances[j].rate;
                        this.setState({ patientInsuranceId: patientInsuranceId, institutionId: institutionId, currencyId: currencyId, rate: rate })
                        this.getServices(institutionId, rate)
                    }
                }
                break;
            case "specialContractId":
                for (var v in this.state.institutions) {
                    if (this.state.institutions[v].id === Number(value)) {
                        let specialContractId = this.state.institutions[v].id;
                        let institutionId = this.state.institutions[v].id;
                        let currencyId = this.state.institutions[v].currencyId;
                        let rate = this.state.institutions[v].rate;
                        this.setState({ specialContractId: specialContractId, institutionId: institutionId, currencyId: currencyId, rate: rate })
                        this.getServices(institutionId, rate)
                    }
                }
                break;
            case "currencyId":
                for (var b in this.state.currencys) {
                    if (this.state.currencys[b].id === Number(value)) {
                        let currencyId = this.state.currencys[b].id;
                        let rate = this.state.currencys[b].rate;
                        this.setState({ currencyId: currencyId, institutionId: 0, rate: rate })
                        this.getServices( 0, rate)
                    }
                }
                break;
            case "billType":
                this.setState({ billType: value })
                break;
            case "serviceId":
                    var s = 0;
                    for (let i in this.state.services) {
                        if (this.state.services[i].id === Number(value.value)) {
                            s = this.state.services[i].fixedCost;
                        }
                    }
                this.setState({ serviceId: value, unitCost: s })
                break;
            case "unitCost":
                this.setState({ unitCost: value })
                break;
             default: 
                     console.log("....")
                     break;   
        }
    }

    doValidate() {
        setTimeout(() => {
            this.validate();
        }, 1000);
    }
    validate() {

        var error = false;
        if (this.state.companyId === null) {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.companyId === "") {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.companyId === 0) {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else {
            this.setState({ companyIdError: "" })
        }

        if (this.state.billCategory === null) {
            this.setState({ billCategoryError: "Bill Category cannot be empty" })
            error = true
        }
        else if (this.state.billCategory === "") {
            this.setState({ billCategoryError: "Bill Category cannot be empty" })
            error = true
        }
        else {
            this.setState({ billCategoryError: "" })
        }


        if (this.state.consultantId === null) {
            this.setState({ consultantIdError: "Consultant cannot be empty" })
            error = true
        }
        else if (this.state.consultantId === 0) {
            this.setState({ consultantIdError: "Consultant cannot be empty" })
            error = true
        }
        else if (this.state.consultantId === "") {
            this.setState({ consultantIdError: "Consultant cannot be empty" })
            error = true
        }
        else {
            this.setState({ consultantIdError: "" })
        }
        if (this.state.serviceId === null) {
            this.setState({ serviceIdError: "Service cannot be empty" })
            error = true
        }
        else if (this.state.serviceId === 0) {
            this.setState({ serviceIdError: "Service cannot be empty" })
            error = true
        }
        else if (this.state.serviceId === "") {
            this.setState({ serviceIdError: "Service cannot be empty" })
            error = true
        }
        else {
            this.setState({ serviceIdError: "" })
        }
        if (this.state.billType === null) {
            this.setState({ billTypeError: "Bill Type cannot be empty" })
            error = true
        }
        else if (this.state.billType === 0) {
            this.setState({ billTypeError: "Bill Type cannot be empty" })
            error = true
        }
        else if (this.state.billType === "") {
            this.setState({ billTypeError: "Bill Type cannot be empty" })
            error = true
        }
        else {
            this.setState({ billTypeError: "" })
        }
        if (this.state.billCategory === 'CASH') {
            if (this.state.currencyId === null) {
                this.setState({ currencyIdError: "Currency cannot be empty" })
                error = true
            }
            else if (this.state.currencyId === 0) {
                this.setState({ currencyIdError: "Currency cannot be empty" })
                error = true
            }
            else if (this.state.currencyId === "") {
                this.setState({ currencyIdError: "Currency cannot be empty" })
                error = true
            }
            else {
                this.setState({ currencyIdError: "" })
            }
        }
        if (this.state.billCategory === 'INSURANCE') {
            if (this.state.patientInsuranceId === null) {
                this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" })
                error = true
            }
            else if (this.state.patientInsuranceId === 0) {
                this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" })
                error = true
            }
            else if (this.state.patientInsuranceId === "") {
                this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" })
                error = true
            }
            else {
                this.setState({ patientInsuranceIdError: "" })
            }
        } 
        if (this.state.billCategory === 'SPECIAL_CONTRACT') {
            if (this.state.specialContractId === null) {
                this.setState({ specialContractIdError: "Special Contract cannot be empty" })
                error = true
            }
            else if (this.state.specialContractId === 0) {
                this.setState({ specialContractIdError: "Special Contract cannot be empty" })
                error = true
            }
            else if (this.state.specialContractId === "") {
                this.setState({ specialContractIdError: "Special Contract cannot be empty" })
                error = true
            }
            else {
                this.setState({ specialContractIdError: "" })
            }
        }
        if (this.state.unitCost === null) {
            this.setState({ unitCostError: "Unit Cost cannot be empty" })
            error = true
        }
        else if (this.state.unitCost === 0) {
            this.setState({ unitCostError: "Unit Cost cannot be empty" })
            error = true
        }
        else if (this.state.unitCost === "") {
            this.setState({ unitCostError: "Unit Cost cannot be empty" })
            error = true
        }
        else {
            this.setState({ unitCostError: "" })
        }
        console.log(" error" + error)
        if(this.state.allowSave === false)
          {
            this.setState({ error: "The quickBill for this patient cannot be saved. Please contact accounts department for Help", showSubmit: false })
          }
        else  if (error === true) {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
        }
        else {
            this.setState({ error: "", showSubmit: true })
        }
    }
    getServices(institutionId, rate)
      {
       this.setState({services: [], options: []})   
       console.log('..... i am '+institutionId+'/'+rate+'/'+this.state.companyId)
       if(institutionId >= 0 && rate > 0 && this.state.companyId > 0)
          { console.log('..... i am ')
            axios.get(localStorage.getItem('host') + '/patient/servicecostlist/' + this.state.companyId + '/' + this.state.billCategory + '/' + institutionId+'/'+rate, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    var options = [];
                    for(var i in res.data)
                        {
                            options.push({value: res.data[i].id, label: res.data[i].name})
                        } 
                    this.setState({services: res.data, options: options});
                }, err => {
                     this.setState({error: err+""})
                    this.props.setLoader(false)
                }
            )
    
          }
      } 
    close() {
        document.getElementById('quickBillAction').style = 'display:none'
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
      save()
        {
            this.props.setLoader(true)
            var s = '';
            for (var i in this.state.services) {
                if (this.state.services[i].id === Number(this.state.serviceId.value)) {
                    s = this.state.services[i];
                }
            }
            var data = {patientId: this.state.patientId, companyId: this.state.companyId, consultantId: this.state.consultantId
                , billCategory: this.state.billCategory, billType: this.state.billType, currencyId: this.state.currencyId, rate: this.state.rate
                , patientInsuranceId: this.state.patientInsuranceId, specialContractId: this.state.specialContractId, treatmentDateTime: this.state.treatmentDateTime
                , comment: this.state.comment, cost: this.state.unitCost, serviceId: s.id, serviceType: s.serviceType, tariffCode: s.tariffCode, cashOnly: s.cashOnly
                , allowEdit: s.allowEdit, other: s.other, name: s.name, serviceClass: s.serviceClass
               }
            axios.post(localStorage.getItem('host') + '/patient/savequickbill', data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.props.setLoader(false)
                    console.log(res.data)
                        this.setState({ msg: res.data });  
                      window.scrollTo(0, 0)
                }, err => {
                     this.setState({error: err+""})
                     window.scrollTo(0, 0)
                    this.props.setLoader(false)
                }
            )   
        }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="quickBillAction" className="page-popup page-popup-overlay" >
            <div className="card" >
                <div className="card-header  bg-info">QuickBill Action</div>
                <div className="card-body"  >
                    <div v-if="quickBill != null">
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">ID No:</span> {this.state.idNumber}</div>
                                    <div className="col"><span className="fw-bold">Cell No:</span> {this.state.mobile}</div>
                                </div>
                                <div className="row">
                                <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                </div>
                               
                            </div>
                        </div>
                                         
                        <br />
                        <div id="quickBillActionMsg"></div>
                        <div >
                            {divMsg}
                                
                                            <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Company</span><select className={this.state.companyIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.companyId} onChange={e => { this.setQuickBill("companyId", e.target.value); this.doValidate() }} >
                                                        <option value="">Choose Company</option>
                                                        {this.state.clusterCompanys.map((item, index) => (
                                                            <option key={index} value={item.id} >{item.companyName}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.companyIdError}</div>
                                                </div></div>
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Bill Category</span><select className={this.state.billCategoryError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.billCategory} onChange={e => { this.setQuickBill("billCategory", e.target.value); this.doValidate() }} >
                                                        <option value="">Choose Bill Category</option>
                                                        {this.state.billCategorys.map((item, index) => (
                                                            <option key={index} value={item} >{item}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.billCategoryError}</div>
                                                </div></div>
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Consultant</span><select className={this.state.consultantIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.consultantId} onChange={e => { this.setQuickBill("consultantId", e.target.value); this.doValidate() }}>
                                                        <option value="">Choose Consultant</option>
                                                        {this.state.users.map((item, index) => (
                                                            <option key={index} value={item.id} >{item.name}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.consultantIdError}</div>
                                                </div>
                                                </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">{this.state.billCategory === "INSURANCE" && (<div className="input-group has-validation">
                                                        <span className="input-group-text">{this.state.billCategory}</span><select className={this.state.patientInsuranceIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.patientInsuranceId} onChange={e => { this.setQuickBill("patientInsuranceId", e.target.value); this.doValidate() }}>
                                                            <option value="0">Choose {this.state.billCategory}</option>
                                                            {this.state.patientInsurances.map((item, index) => (
                                                                <option key={index} value={item.id} >{item.name} - {item.currencyName}</option>
                                                            ))}
                                                        </select><div className="invalid-feedback">{this.state.patientInsuranceIdError}</div></div>) }
                                                        {this.state.billCategory === "SPECIAL_CONTRACT" ? (<div className="input-group has-validation">
                                                            <span className="input-group-text">{this.state.billCategory}</span> <select className={this.state.specialContractIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.specialContractId} onChange={e => { this.setQuickBill("specialContractId", e.target.value); this.doValidate() }} >
                                                                <option value="0">Choose Special Contract</option>
                                                                {this.state.institutions.map((item, index) => (
                                                                    <option key={index} value={item.id} >{item.name} - {item.currencyName}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback">{this.state.specialContractIdError}</div></div>) : (<span></span>)}
                                                        {this.state.billCategory === "CASH" ? (<div className="input-group has-validation">
                                                            <span className="input-group-text">{this.state.billCategory}</span><select className={this.state.currencyIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.currencyId} onChange={e => { this.setQuickBill("currencyId", e.target.value); this.doValidate() }}>
                                                                <option value="0">Choose {this.state.billCategory}</option>
                                                                {this.state.currencys.map((item, index) => (
                                                                    <option key={index} value={item.id} >{item.name}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback">{this.state.currencyIdError}</div></div>) : (<span></span>)}
                                                    </div>
                                                <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Service</span>
                                                    <Select
                                                    value={this.state.serviceId}
                                                    className={this.state.serviceIdError !== "" ? "form-control is-invalid" : "form-control"}
                                                    onChange={e => {this.setQuickBill("serviceId", e); this.doValidate()}}
                                                    options={this.state.options}
                                                /><div className="invalid-feedback">{this.state.serviceIdError}</div></div></div>
                                                    <div className="col" align="right"><div className="input-group"><span className="input-group-text">Treatment Date</span>
                                                        <input type="text" className={this.state.treatmentDateTimeError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.treatmentDateTime} onChange={e => {this.setQuickBill("treatmentDateTime", e.target.value); this.doValidate()  }} /> 
                                                        <MyDateTimePicker  value={this.state.treatmentDateTime} maxDate={ date1} onChange={(d)=> { this.setQuickBill("treatmentDateTime", d); this.doValidate()  }} />
                                                        <div className="invalid-feedback">{this.state.treatmentDateTimeError}</div> </div></div>
                                            </div>
                                           <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Type</span><select className={this.state.billTypeError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.billType} onChange={e => { this.setQuickBill("billType", e.target.value); this.doValidate() }}>
                                                    <option value="" >Choose</option>
                                                        <option value="PROCEDURE" >PROCEDURE</option>
                                                        <option value="VACCINE">VACCINE</option>
                                                           </select><div className="invalid-feedback">{this.state.billTypeError}</div>
                                                </div></div>
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Unit Cost</span><input type="text" className={this.state.unitCostError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.unitCost} onChange={e => { this.setQuickBill("unitCost", e.target.value); this.doValidate() }} /><div className="invalid-feedback">{this.state.unitCostError}</div>
                                                </div></div>
                                                <div className="col"></div>
                                            </div>
                                </div>
                        <div className="row">
                            <div className="col" align="center">{this.state.showSubmit === true ?(<button onClick={() => this.save()} id="submitButton2" className="btn btn-primary" >Save</button>):(<span></span>)}</div>
                            <div className="col" align="center"><button onClick={() => this.close()} className="btn btn-primary">Close</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)

    }
}      