import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Select from 'react-select';
import MyDateTimePicker from "../mydatetimepicker";

const now = new Date();
let date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}
export default class VisitAction extends Component {
    constructor(props) {
        super(props);
        console.log('.......')
    }
    state = { visitOrder: 1, serviceCostId: 0, searchDiagnosis: '', diagnosis: '', billId: 0, zwlStyle: '#000000', usdStyle: '#000000', lastVisitDate: '', owingAmountZwl: 0.00, owingAmountUsd: 0.00, allowSave: true, toothSurfaces: [{id:'BU', name: 'Buccal'}, {id:'DI', name: 'Distal'}, {id:'OC', name: 'Occlusal'}, {id:'LA', name: 'Labial'}, {id:'LI', name: 'Lingual'}, {id:'ME', name: 'Mesial'}, {id: 'PA', name: 'Palatal'}], toothInfos: [], index: 0, hospitals: [], cashOnly: '',
        visitDetails: true, billDetails: false, diagnosisDetails: false, options: [], id: 0, patientId: 0, institutionId: 0, speciality: '', visit: '', clusterCompanys: [], billCategorys: [], users: [], patientInsurances: [], institutions: [], referringConsultants: [], wards: [], beds: [], services: [], billItems: [], diagnosisList: [], patientDiagnosis: [], currencys: [], optionsReferringConsultants: [], investigationRequests: [], requestVisitId: 0
        , fullname: '', idNumber: '', mobile: '', gender: '', dateOfBirth: '', age: '', companyId: '', companyIdError: '', billCategory: '', billCategoryError: '', consultantId: '', consultantIdError: '', wardNameError: '', wardName: '', attendingFromId: '', attendingFromIdError: '', visitOption: '', visitOptionError: ''
        , patientInsuranceId: '', patientInsuranceIdError: '', specialContractId: '', specialContractIdError: '', currencyId: '', currencyIdError: '', treatmentDateTime: date1, treatmentDateTimeError: '', referringConsultantId: ''
        , referringConsultantIdError: '', visitType: 'OUTPATIENT', visitTypeError: '', doctorName: '', doctorNameError: '', doctorAhfoz: '', doctorAhfozError: '', doctorEmail: '', doctorMobile: '', showReferring: false, wardId: '', wardIdError: '', bedId: '', bedIdError: '', opdPharmacy: ''
        ,  health263Claim: false, canSendToHealth263: false, canSendToHealth263WithOverride: false, canCancelHealth263WithBio: false, canCancelHealth263: false, patientEnrolled: false, health263MessageShow: false, combinedMessages: '', cimasCount: 0, lastVisitDays: 0
        , cashPayable: 0, insurancePayable: 0, shortfallPayable: 0, specialContractPayable: 0, totalPayable: 0
        , cashPaid: 0, insurancePaid: 0, shortfallPaid: 0, specialContractPaid: 0, totalPaid: 0 
        , cashOwing: 0, insuranceOwing: 0, shortfallOwing: 0, specialContractOwing: 0, totalOwing: 0
        , cashRefunded: 0, shortfallRefunded: 0, totalRefunded: 0
        , bookingNumber: '', bookingNumberError: '', billingStartDateTime: '', billingEndDateTime: '', comment: '', flagPatient: '', showHospitalName: false, hospitalName: {label: '', value: ''}, hospitalNameError:'', hosName: '', hosNameError: '', colors: ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"], attendingFroms: []
    }

    toggleTabs(name) {
        switch (name) {
            case "billDetails":
                this.setState({ visitDetails: false, billDetails: true, diagnosisDetails: false })
                break
            case "diagnosisDetails":
                this.setState({ visitDetails: false, billDetails: false, diagnosisDetails: true, searchDiagnosis: '', diagnosisList: [] })
                break
            default:
                this.setState({ visitDetails: true, billDetails: false, diagnosisDetails: false })
                break

        }
    }
    getVisit(id, patientId, msg, oldVisitId) {
        this.props.setLoader(true)
        this.setState({
            visitOrder: 1,  visitDetails: true, billDetails: false, diagnosisDetails: false, id: 0, billId: 0, patientId: 0, institutionId: 0, speciality: '', visit: '', clusterCompanys: [], billCategorys: [], users: [], patientInsurances: [], institutions: [], referringConsultants: [], wards: [], beds: [], services: [], billItems: [], diagnosisList: [], patientDiagnosis: [], currencys: [], optionsReferringConsultants: [], allowSave: true, msg: msg, error: '', hospitals: [], investigationRequests: [], cashOnly: ''
            , fullname: '', idNumber: '', mobile: '', gender: '', dateOfBirth: '', age: '', companyId: '', companyIdError: '', billCategory: '', billCategoryError: '', consultantId: '', consultantIdError: '', wardNameError: '', wardName: '', attendingFromId: '', attendingFromIdError: '', visitOption: '', visitOptionError: '', cimasCount: 0, lastVisitDays: 0
            , patientInsuranceId: '', patientInsuranceIdError: '', specialContractId: '', specialContractIdError: '', currencyId: '', currencyIdError: '', treatmentDateTime: date1, treatmentDateTimeError: '', referringConsultantId: '', requestVisitId: 0
            , referringConsultantIdError: '', visitType: 'OUTPATIENT', visitTypeError: '',doctorName: '', doctorNameError: '', doctorAhfoz: '', doctorAhfozError: '', doctorEmail: '', doctorMobile: '', showReferring: false, wardId: '', wardIdError: '', bedId: '', bedIdError: '', opdPharmacy: '',
            health263Claim: false, canSendToHealth263: false, canSendToHealth263WithOverride: false, canCancelHealth263WithBio: false, canCancelHealth263: false, patientEnrolled: false, health263MessageShow: false, combinedMessages: '', diagnosisText: '', searchDiagnosis: '', diagnosis: '' , bookingNumber: '', bookingNumberError: '', billingStartDateTime: '', billingEndDateTime: '', comment: '', flagPatient: '', hospitalName: {label: '', value: ''}, hosName: '', hosNameError: '', cashRefunded: 0, shortfallRefunded: 0, totalRefunded: 0, attendingFroms: []
        })
        axios.get(localStorage.getItem('host') + '/patient/visitaction/' + id + '/' + patientId + '/' + localStorage.getItem('companyId')+"/"+oldVisitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                var optionsReferringConsultants = [];
                var referringConsultantId = ''
                for(var i in res.data.referringConsultants)
                {
                    optionsReferringConsultants.push({value: res.data.referringConsultants[i].id, label: res.data.referringConsultants[i].name})
                    if(Number(res.data.referringConsultantId) === Number(res.data.referringConsultants[i].id))
                       {
                        referringConsultantId = {value: res.data.referringConsultants[i].id, label: res.data.referringConsultants[i].name}
                       }
                } 
                var hospitals = [];
                var hospitalName = ''
                for(var c in res.data.hospitals)
                {
                    hospitals.push({value: res.data.hospitals[c], label: res.data.hospitals[c]})
                    if(res.data.bill.hospitalName === res.data.hospitals[c])
                       {
                        hospitalName = {value: res.data.hospitals[c], label: res.data.hospitals[c]}
                       }
                } 
                this.setState({
                    id: id, visitOrder: res.data.visitOrder, patientId: res.data.patientId, billId: res.data.billId, speciality: res.data.speciality, clusterCompanys: res.data.clusterCompanys, billCategorys: res.data.billCategorys, users: res.data.users, patientInsurances: res.data.patientInsurances, institutions: res.data.institutions, referringConsultants: res.data.referringConsultants, optionsReferringConsultants: optionsReferringConsultants, wards: res.data.wards, beds: res.data.beds, billItems: res.data.billItems, patientDiagnosis: res.data.patientDiagnosis, currencys: res.data.currencys, allowSave: res.data.allowSave
                    , fullname: res.data.fullname, idNumber: res.data.idNumber, mobile: res.data.mobile, gender: res.data.gender, dateOfBirth: res.data.dateOfBirth, age: res.data.age, companyId: res.data.companyId, billCategory: res.data.billCategory, consultantId: res.data.consultantId, requestVisitId: res.data.requestVisitId
                    , patientInsuranceId: res.data.patientInsuranceId, specialContractId: res.data.specialContractId, currencyId: res.data.bill.currencyId, treatmentDateTime: res.data.treatmentDateTime, referringConsultantId: referringConsultantId, visitOption: res.data.visitOption, opdPharmacy: res.data.opdPharmacy, cimasCount: res.data.cimasCount, lastVisitDays: res.data.lastVisitDays, investigationRequests: res.data.investigationRequests
                    , visitType: res.data.visitType, wardId: res.data.wardId, bedId: res.data.bedId, bedIdError: res.data.bedIdError,
                    health263Claim: res.data.health263Claim, canSendToHealth263: res.data.canSendToHealth263, canSendToHealth263WithOverride: res.data.canSendToHealth263WithOverride, canCancelHealth263WithBio: res.data.canCancelHealth263WithBio, canCancelHealth263: res.data.canCancelHealth263, patientEnrolled: res.data.patientEnrolled, health263MessageShow: res.data.health263MessageShow, combinedMessages: res.data.combinedMessages, diagnosisText: res.data.diagnosisText, cashPayable: res.data.bill.cashPayable, insurancePayable: res.data.bill.insurancePayable, shortfallPayable: res.data.bill.shortfallPayable, specialContractPayable: res.data.bill.specialContractPayable, totalPayable: res.data.bill.totalPayable
                    , cashPaid: res.data.bill.cashPaid, insurancePaid: res.data.bill.insurancePaid, shortfallPaid: res.data.bill.shortfallPaid, specialContractPaid: res.data.bill.specialContractPaid, totalPaid: res.data.bill.totalPaid, rate: res.data.bill.rate, wardName: res.data.bill.wardName, attendingFromId: res.data.attendingFromId
                    , cashOwing: res.data.bill.cashOwing, insuranceOwing: res.data.bill.insuranceOwing, shortfallOwing: res.data.bill.shortfallOwing, specialContractOwing: res.data.bill.specialContractOwing, totalOwing: res.data.bill.totalOwing, authorisationNumber: res.data.bill.authorisationNumber, authorisationAmount: res.data.bill.authorisationAmount
                    , cashRefunded: res.data.bill.cashRefunded, shortfallRefunded: res.data.bill.shortfallRefunded, totalRefunded: res.data.bill.totalRefunded
                    , zwlStyle: res.data.zwlStyle, usdStyle: res.data.usdStyle, lastVisitDate: res.data.lastVisitDate, owingAmountZwl:res.data.owingAmountZwl, owingAmountUsd: res.data.owingAmountUsd, bookingNumber: res.data.bill.bookingNumber, billingStartDateTime: res.data.bill.billingStartDateTime, billingEndDateTime: res.data.bill.billingEndDateTime, comment: res.data.bill.comment, flagPatient: res.data.flagPatient, hospitalName: hospitalName, hospitalNameError: '', attendingFroms: res.data.attendingFroms, hospitals: hospitals, visitCountInAYear: res.data.visitCountInAYear
                });
                if(res.data.billCategory === "CASH")
                   {   
                    console.log("CASH get Services")   
                    setTimeout(() => { this.getServices(0, res.data.bill.rate)}, 1000);
                   }
                else if(res.data.billCategory === "SPECIAL_CONTRACT")
                   {
                    setTimeout(() => { this.getServices(res.data.specialContractId, res.data.bill.rate)}, 1000);
                   }  
                else
                   {
                    for (let z in res.data.patientInsurances) {
                        if (res.data.patientInsurances[z].id === res.data.patientInsuranceId) {     
                            console.log("Insurance get Services")
                    setTimeout(() => { this.getServices(res.data.patientInsurances[z].institutionId, res.data.bill.rate)}, 1000);
                        }
                    }
                   }    
                document.getElementById('visitAction').style = 'display:block'
                window.scrollTo(0, 0);
                this.doValidate();
                this.props.setLoader(false)
            }, err => {
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )

    }
    updateVisit(billId)
      {
        this.props.setLoader(true)
        this.setState({health263Claim: false, canSendToHealth263: false, canSendToHealth263WithOverride: false, canCancelHealth263WithBio: false, canCancelHealth263: false, patientEnrolled: false, health263MessageShow: false, combinedMessages: ''})
        axios.get(localStorage.getItem('host') + '/patient/gethealth263update/' + billId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({health263Claim: res.data.health263Claim, canSendToHealth263: res.data.canSendToHealth263, canSendToHealth263WithOverride: res.data.canSendToHealth263WithOverride, canCancelHealth263WithBio: res.data.canCancelHealth263WithBio, canCancelHealth263: res.data.canCancelHealth263, patientEnrolled: res.data.patientEnrolled, health263MessageShow: res.data.health263MessageShow, combinedMessages: res.data.combinedMessages, 
                    cashPayable: res.data.cashPayable, insurancePayable: res.data.insurancePayable, shortfallPayable: res.data.shortfallPayable, specialContractPayable: res.data.specialContractPayable, totalPayable: res.data.totalPayable,
                    cashPaid: res.data.cashPaid, insurancePaid: res.data.insurancePaid, shortfallPaid: res.data.shortfallPaid, specialContractPaid: res.data.specialContractPaid, totalPaid: res.data.totalPaid, 
                    cashOwing: res.data.cashOwing, insuranceOwing: res.data.insuranceOwing, shortfallOwing: res.data.shortfallOwing, specialContractOwing: res.data.specialContractOwing, totalOwing: res.data.totalOwing, authorisationNumber: res.data.authorisationNumber, authorisationAmount: res.data.authorisationAmount});
                window.scrollTo(0, 0);
                   this.props.setLoader(false)
            }, err => {
                 this.setState({error: err+""})
                 window.scrollTo(0, 0);
                this.props.setLoader(false)
            }
        )

      }
    setVisit(name, value) {
        switch (name) {
            case "companyId":
                for (var i in this.state.clusterCompanys) {
                    if (Number(this.state.clusterCompanys[i].id) === Number(value)) {
                        let companyId = this.state.clusterCompanys[i].id;
                        let users = this.state.clusterCompanys[i].users;
                        let patientInsurances = this.state.clusterCompanys[i].patientInsurances;
                        let institutions = this.state.clusterCompanys[i].institutions;
                        let currencys = this.state.clusterCompanys[i].currencys;
                        let speciality = this.state.clusterCompanys[i].speciality;
                        let zwlStyle = this.state.clusterCompanys[i].zwlStyle;
                        let usdStyle = this.state.clusterCompanys[i].usdStyle;
                        let lastVisitDate = this.state.clusterCompanys[i].lastVisitDate;
                        let owingAmountZwl = this.state.clusterCompanys[i].owingAmountZwl;
                        let owingAmountUsd = this.state.clusterCompanys[i].owingAmountUsd;
                        let allowSave = this.state.clusterCompanys[i].allowSave
                        let flagPatient = this.state.clusterCompanys[i].flagPatient
                        let attendingFroms = this.state.clusterCompanys[i].attendingFroms
                        let visitCountInAYear = this.state.clusterCompanys[i].visitCountInAYear 
                        let cimasCount = this.state.clusterCompanys[i].cimasCount;
                        let lastVisitDays = this.state.clusterCompanys[i].lastVisitDays;
                        let visitType = speciality === "HOSPITAL"?"INPATIENT":this.state.visitType
                        console.log('Allow Save'+allowSave)
                        this.setState({error: '', visitType: visitType, companyId: companyId, users: users, patientInsurances: patientInsurances, institutions: institutions, currencys: currencys, speciality: speciality, patientInsuranceId: 0, specialContractId: 0, institutionId: 0, currencyId: 0, rate: 0, billCategory: '', consultantId: 0, referringConsultantId: 0, wardId: 0, bedId: 0, zwlStyle: zwlStyle, usdStyle: usdStyle, lastVisitDate: lastVisitDate, owingAmountZwl:owingAmountZwl, owingAmountUsd: owingAmountUsd, allowSave: allowSave, flagPatient: flagPatient, attendingFroms: attendingFroms, visitCountInAYear: visitCountInAYear, cimasCount: cimasCount, lastVisitDays: lastVisitDays })
                    }
                }
                break;
            case "billCategory":
                let currencyId = 0
                let rate = 0
                if(value === "CASH" && this.state.currencys.length === 1)
                    {
                     currencyId = this.state.currencys[0].id
                     rate = this.state.currencys[0].rate
                    }
                this.setState({ billCategory: value, patientInsuranceId: 0, specialContractId: 0, institutionId: 0, currencyId: currencyId, rate: rate })
                setTimeout(() => { this.getServices(0, rate) }, 1000)
                break;
            case "consultantId":
                this.setState({ consultantId: value })
                break;
            case "treatmentDateTime":
                this.setState({ treatmentDateTime: value })
                break;
            case "patientInsuranceId":
                for (let j in this.state.patientInsurances) {
                    if (this.state.patientInsurances[j].id === Number(value)) {
                        let patientInsuranceId = this.state.patientInsurances[j].id;
                        let institutionId = this.state.patientInsurances[j].institutionId;
                        let currencyId = this.state.patientInsurances[j].currencyId;
                        let rate = this.state.patientInsurances[j].rate;
                        this.setState({ patientInsuranceId: patientInsuranceId, institutionId: institutionId, currencyId: currencyId, rate: rate })
                        this.getServices(institutionId, rate)
                    }
                }
                break;
            case "specialContractId":
                for (var v in this.state.institutions) {
                    if (this.state.institutions[v].id === Number(value)) {
                        let specialContractId = this.state.institutions[v].id;
                        let institutionId = this.state.institutions[v].id;
                        let currencyId = this.state.institutions[v].currencyId;
                        let rate = this.state.institutions[v].rate;
                        this.setState({ specialContractId: specialContractId, institutionId: institutionId, currencyId: currencyId, rate: rate })
                        this.getServices(institutionId, rate)
                    }
                }
                break;
            case "currencyId":
                for (var b in this.state.currencys) {
                    if (this.state.currencys[b].id === Number(value)) {
                        let currencyId = this.state.currencys[b].id;
                        let rate = this.state.currencys[b].rate;
                        this.setState({ currencyId: currencyId, institutionId: 0, rate: rate })
                        this.getServices( 0, rate)
                    }
                }
                break;
            case "visitType":
                this.setState({ visitType: value })
                break;
            case "bookingNumber":
                this.setState({ bookingNumber: value })
                break;
            case "hospitalName":
                this.setState({ hospitalName: value })
                break;
            case "wardName":
                this.setState({ wardName: value })
                break;
            case "attendingFromId":
                this.setState({ attendingFromId: value })
                break;
            case "visitOption":
                this.setState({ visitOption: value })
                break;
            case "opdPharmacy":
                this.setState({ opdPharmacy: value })
                break;
            case "visitOrder":
                this.setState({ visitOrder: value })
                break;
            case "referringConsultantId":
                this.setState({ referringConsultantId: value })
                break;
            case "wardId":
                for (var n in this.state.wards) {
                    if (this.state.wards[n].id === Number(value)) {
                        var wardId = this.state.wards[n].id;
                        var beds = this.state.wards[n].beds;
                        this.setState({ wardId: wardId, beds: beds })
                    }
                }
                break;
            case "bedId":
                let serviceCostId = 0
                let list = this.state.billItems.slice();
                for(let n in this.state.beds)
                   {
                    if (this.state.beds[n].id === Number(value)) {
                        serviceCostId = this.state.beds[n].serviceId
                    }
                   }
                   if(serviceCostId > 0)
                    {
                        const now = new Date();
                        var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
                        
                        var s = '';
                        for (let i in this.state.services) {
                            if (this.state.services[i].id === Number(serviceCostId)) {
                                s = this.state.services[i];
                            }
                        }
                        if(s.id > 0 && this.state.id === 0)
                           {
                        list.push({id: 0, serviceId: s.id, name: s.name, billItemType: 'SERVICE', serviceType: s.serviceType, serviceClass: s.serviceClass
                        , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: s.quantity, unitCost: s.fixedCost, cost: s.fixedCost, totalCost: s.fixedCost, cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, min: this.getMargin(s.fixedCost, s.margin, 'MIN'), max: this.getMargin(s.fixedCost, s.margin, 'MAX'), unitCostError: '', toothInfo: '[]'});
                            for (var index in s.services)
                            {
                                var ss = s.services[index];
                                list.push({id: 0, serviceId: ss.id, name: ss.name, billItemType: 'SERVICE', serviceType: ss.serviceType, serviceClass: ss.serviceClass
                                    , tariffCode: ss.tariffCode, billStartDate: date1, billEndDate: date1, quantity: ss.quantity, unitCost: ss.fixedCost, cost: ss.fixedCost, totalCost: ss.fixedCost, cashOnly: ss.cashOnly, allowEdit: ss.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: ss.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, min: this.getMargin(ss.fixedCost, ss.margin, 'MIN'), max: this.getMargin(ss.fixedCost, ss.margin, 'MAX'), unitCostError: '', toothInfo: '[]'});
                            }
                        }
            

                    }  
                this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount) 
                this.setState({ bedId: value, billItems: list })
               
                break;
             default: 
                     console.log("....")
                     break;   
        }
    }

    doValidate() {
        setTimeout(() => {
            this.validate();
        }, 1000);
    }
    validate() {

        var error = false;
        if (this.state.companyId === null) {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.companyId === "") {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.companyId === 0) {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else {
            this.setState({ companyIdError: "" })
        }

        if (this.state.billCategory === null) {
            this.setState({ billCategoryError: "Bill Category cannot be empty" })
            error = true
        }
        else if (this.state.billCategory === "") {
            this.setState({ billCategoryError: "Bill Category cannot be empty" })
            error = true
        }
        else {
            this.setState({ billCategoryError: "" })
        }


        if (this.state.consultantId === null) {
            this.setState({ consultantIdError: "Consultant cannot be empty" })
            error = true
        }
        else if (this.state.consultantId === 0) {
            this.setState({ consultantIdError: "Consultant cannot be empty" })
            error = true
        }
        else if (this.state.consultantId === "") {
            this.setState({ consultantIdError: "Consultant cannot be empty" })
            error = true
        }
        else {
            this.setState({ consultantIdError: "" })
        }
        if (this.state.billCategory === 'CASH') {
            if (this.state.currencyId === null) {
                this.setState({ currencyIdError: "Currency cannot be empty" })
                error = true
            }
            else if (this.state.currencyId === 0) {
                this.setState({ currencyIdError: "Currency cannot be empty" })
                error = true
            }
            else if (this.state.currencyId === "") {
                this.setState({ currencyIdError: "Currency cannot be empty" })
                error = true
            }
            else {
                this.setState({ currencyIdError: "" })
            }
        }
        if (this.state.billCategory === 'INSURANCE') {
            if (this.state.patientInsuranceId === null) {
                this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" })
                error = true
            }
            else if (this.state.patientInsuranceId === 0) {
                this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" })
                error = true
            }
            else if (this.state.patientInsuranceId === "") {
                this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" })
                error = true
            }
            else {
                this.setState({ patientInsuranceIdError: "" })
            }
          if(this.state.speciality === 'CASUALTY')
             {

            if (this.state.bookingNumber === null) {
                this.setState({ bookingNumberError: "Booking Number cannot be empty" })
                error = true
            }
            else if (this.state.bookingNumber === 0) {
                this.setState({ bookingNumberError: "Booking Number cannot be empty" })
                error = true
            }
            else if (this.state.bookingNumber === "") {
                this.setState({ bookingNumberError: "Booking Number cannot be empty" })
                error = true
            }
            else {
                this.setState({ bookingNumberError: "" })
            }
             }  
        } 
        if (this.state.billCategory === 'SPECIAL_CONTRACT') {
            if (this.state.specialContractId === null) {
                this.setState({ specialContractIdError: "Special Contract cannot be empty" })
                error = true
            }
            else if (this.state.specialContractId === 0) {
                this.setState({ specialContractIdError: "Special Contract cannot be empty" })
                error = true
            }
            else if (this.state.specialContractId === "") {
                this.setState({ specialContractIdError: "Special Contract cannot be empty" })
                error = true
            }
            else {
                this.setState({ specialContractIdError: "" })
            }
        }
        if (this.state.speciality === 'PAEDIATRICCARDIOLOGIST') {
            if (this.state.attendingFromId === null) {
                this.setState({ attendingFromIdError: "Attending From cannot be empty" })
                error = true
            }
            else if (this.state.attendingFromId === 0) {
                this.setState({ attendingFromIdError: "Attending From cannot be empty" })
                error = true
            }
            else if (this.state.attendingFromId === "") {
                this.setState({ attendingFromIdError: "Attending From cannot be empty" })
                error = true
            }
            else {
                this.setState({ attendingFromIdError: "" })
            }
        }
        if (this.state.visitType === "OUTPATIENT" && this.state.speciality === 'ONCOLOGY') {
            if (this.state.visitOption === null) {
                this.setState({ visitOptionError: "Visit Option cannot be empty" })
                error = true
            }
            else if (this.state.visitOption === "") {
                this.setState({ visitOptionError: "Visit Option cannot be empty" })
                error = true
            }
            else {
                this.setState({ visitOptionError: "" })
            }
        }
        if ((this.state.speciality === 'GENERAL_PRACTITIONER' && this.state.visitType === 'INPATIENT') || this.state.speciality === 'NEUROLOGY' || this.state.speciality === 'ONCOLOGY' || this.state.speciality === 'PHYSICIAN' || this.state.speciality === 'PAEDIATRICS' || this.state.speciality === 'UROLOGY' || this.state.speciality === 'NEUROSUGERY' || this.state.speciality === 'RADIOLOGY' || this.state.speciality === 'LABORATORY' || this.state.speciality === 'ORTHOPEDICS' || this.state.speciality === 'GYNAECOLOGY_OBSTRETICS' || this.state.speciality === 'ANAESTHETIST' || this.state.speciality === 'NEPHROLOGIST' || this.state.speciality === 'PAEDIATRICCARDIOLOGIST' || this.state.speciality === 'REHABILITATION' || (this.state.speciality === 'OPD' && this.state.visitType === 'INPATIENT') || this.state.speciality === 'HAEMATOLOGY') {
            if (this.state.referringConsultantId === null) {
                this.setState({ referringConsultantIdError: "Referring Consultant cannot be empty" })
                error = true
            }
            else if (this.state.referringConsultantId === 0) {
                this.setState({ referringConsultantIdError: "Referring Consultant cannot be empty" })
                error = true
            }
            else if (this.state.referringConsultantId === "") {
                this.setState({ referringConsultantIdError: "Referring Consultant cannot be empty" })
                error = true
            }
            else {
                this.setState({ referringConsultantIdError: "" })
            }
            if(this.state.visitType === "INPATIENT" && this.state.speciality !== 'OPD')
              {

                if (this.state.hospitalName === null) {
                    this.setState({ hospitalNameError: "Hospital Name cannot be empty" })
                    error = true
                }
                else if (this.state.hospitalName === 0) {
                    this.setState({ hospitalNameError: "Hospital Name cannot be empty" })
                    error = true
                }
                else if (this.state.hospitalName === "") {
                    this.setState({ hospitalNameError: "Hospital Name cannot be empty" })
                    error = true
                }
                else {
                    this.setState({ hospitalNameError: "" })
                }
                if (this.state.wardName === null) {
                    this.setState({ wardNameError: "Ward Name cannot be empty" })
                    error = true
                }
                else if (this.state.wardName === 0) {
                    this.setState({ wardNameError: "Ward Name cannot be empty" })
                    error = true
                }
                else if (this.state.wardName === "") {
                    this.setState({ wardNameError: "Ward Name cannot be empty" })
                    error = true
                }
                else {
                    this.setState({ wardNameError: "" })
                }
              }
        }
        if(this.state.speciality === 'HOSPITAL' || (this.state.speciality === 'OPD' && this.state.visitType === 'INPATIENT') )
           {
            if (this.state.wardId === null) {
                this.setState({ wardIdError: "Ward cannot be empty" })
                error = true
            }
            else if (this.state.wardId === 0) {
                this.setState({ wardIdError: "Ward cannot be empty" })
                error = true
            }
            else if (this.state.wardId === "") {
                this.setState({ wardIdError: "Ward cannot be empty" })
                error = true
            }
            else {
                this.setState({ wardIdError: "" })
            }
            if (this.state.bedId === null) {
                this.setState({ bedIdError: "Bed cannot be empty" })
                error = true
            }
            else if (this.state.bedId === 0) {
                this.setState({ bedIdError: "Bed cannot be empty" })
                error = true
            }
            else if (this.state.bedId === "") {
                this.setState({ bedIdError: "Bed cannot be empty" })
                error = true
            }
            else {
                this.setState({ bedIdError: "" })
            }
           }
        var billItems = this.state.billItems;
        if (billItems === null) {
            this.setState({ billItemsError: "Bill Items cannot be empty" })
            error = true
        }
        else if (billItems.length === 0) {
            this.setState({ billItemsError: "Bill Items cannot be empty" })
            error = true
        }
        else {
            
             for(var i in billItems)
                {
                 if(billItems[i].allowEdit)
                   {
                    if (billItems[i].unitCost === null) {
                        billItems[i].unitCostError =  "Unit Cost cannot be empty"
                        error = true
                    }
                    else if (billItems[i].unitCost === "") {
                        billItems[i].unitCostError =  "Unit Cost cannot be empty"
                        error = true
                    }
                    else if (billItems[i].unitCost >= 0 && billItems[i].unitCost > billItems[i].max) {
                        billItems[i].unitCostError =  "Unit Cost cannot be greater than "+billItems[i].max
                        error = true
                    }
                    else if (billItems[i].unitCost >= 0 && billItems[i].unitCost < billItems[i].min) {
                        billItems[i].unitCostError =  "Unit Cost cannot be less than "+billItems[i].min
                        error = true
                    }
                    else if (billItems[i].unitCost < 0 && billItems[i].unitCost > 0) {
                        billItems[i].unitCostError =  "Unit Cost cannot be greater than 0"
                        error = true
                    }
                    else if (billItems[i].unitCost < 0 && billItems[i].unitCost < billItems[i].min) {
                        billItems[i].unitCostError =  "Unit Cost cannot be less than "+billItems[i].min
                        error = true
                    }
                    else {
                        billItems[i].unitCostError =  "" 
                    }


                   }
              this.setState({ billItemsError: "", billItems: billItems })   
                }
        }
        console.log(" error" + error)
        if(this.state.allowSave === false)
          {
            this.setState({ error: "The visit for this patient cannot be saved. Please contact accounts department for Help", showSubmit: false })
          }
        else  if (error === true) {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
        }
        else {
            this.setState({ error: "", showSubmit: true })
        }
    }
    getServices(institutionId, rate)
      {
       this.setState({services: [], options: []})   
       console.log('..... i am '+institutionId+'/'+rate+'/'+this.state.companyId)
       if(institutionId >= 0 && rate > 0 && this.state.companyId > 0)
          { console.log('..... i am ')
            axios.get(localStorage.getItem('host') + '/patient/servicecostlist/' + this.state.companyId + '/' + this.state.billCategory + '/' + institutionId+'/'+rate, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    var options = [];
                    for(var i in res.data)
                        {
                            options.push({value: res.data[i].id, label: res.data[i].name})
                        } 
                    this.setState({services: res.data, options: options});
                }, err => {
                     this.setState({error: err+""})
                    this.props.setLoader(false)
                }
            )
    
          }
      }   
    addServices()
       {  console.log('......... service cost id '+JSON.stringify(this.state.serviceCostId)) 
        if(this.state.serviceCostId.value > 0)
           {  console.log('......... service cost id '+this.state.serviceCostId.value) 
            const now = new Date();
            var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
            let list = this.state.billItems.slice();
            var s = '';
            for (var i in this.state.services) {
                if (this.state.services[i].id === Number(this.state.serviceCostId.value)) {
                    s = this.state.services[i];
                }
            }
            if(this.state.cashOnly !== '' )
               {
                s.cashOnly = this.state.cashOnly;
                console.log("........ Cash Only "+s.cashOnly+" Override Cash Only "+this.state.cashOnly)
                this.setState({cashOnly: ''})
               }
            list.push({id: 0, serviceId: s.id, name: s.name, billItemType: 'SERVICE', serviceType: s.serviceType, serviceClass: s.serviceClass
            , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: s.quantity, unitCost: s.fixedCost, cost: s.fixedCost, totalCost: s.fixedCost, cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, min: this.getMargin(s.fixedCost, s.margin, 'MIN'), max: this.getMargin(s.fixedCost, s.margin, 'MAX'), unitCostError: '', toothInfo: '[]'});
                for (var index in s.services)
                {
                    var ss = s.services[index];
                    list.push({id: 0, serviceId: ss.id, name: ss.name, billItemType: 'SERVICE', serviceType: ss.serviceType, serviceClass: ss.serviceClass
                        , tariffCode: ss.tariffCode, billStartDate: date1, billEndDate: date1, quantity: ss.quantity, unitCost: ss.fixedCost, cost: ss.fixedCost, totalCost: ss.fixedCost, cashOnly: ss.cashOnly, allowEdit: ss.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: ss.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, min: this.getMargin(ss.fixedCost, ss.margin, 'MIN'), max: this.getMargin(ss.fixedCost, ss.margin, 'MAX'), unitCostError: '', toothInfo: '[]'});
                }
            this.setState({ billItems: list, serviceCostId: {label: '', value: ''} });
            this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
            this.doValidate()
           }
       }  
    getMargin(unitCost, margin, type)
       {
        let val = 0
        if(type === 'MIN')
            {
              if(unitCost < 0)
                {
                val = unitCost +  Math.ceil(unitCost * (margin / 100));  
                }  
             else
                {
                val = unitCost -  Math.ceil(unitCost * (margin / 100));    
                val = val < 0?0:val
                }   
            }
        else{
            if(unitCost < 0)
                {
                val = unitCost -  Math.ceil(unitCost * (margin / 100));  
                val = val > 0?0:val
                }  
             else
                {
                val = unitCost +  Math.ceil(unitCost * (margin / 100));    
                }   
            
            }    

       return val;    
       }   
    setBillItem(name, index, value)   
       {
        var list = this.state.billItems;   
       switch(name)
          {    
             case "billStartDate":
                list[index].billStartDate = value
                break;
            case "billEndDate":
                list[index].billEndDate = value
                break;   
            case "quantity":
                list[index].quantity = value
                this.calcQuantity(index, list[index].quantity)
                break;   
            case "unitCost":
                list[index].unitCost = value
                this.calcQuantity(index, list[index].quantity)
                break;   
            case "instruction":
                list[index].instruction = value
                break;     
            case "authoAmount":
                list[index].authoAmount = value 
                this.calcQuantity(index, list[index].quantity)
                break;    
            case "cashOnly":
                list[index].cashOnly = value 
                this.calcQuantity(index, list[index].quantity)
                break;  
             default: 
                     console.log("....")
                     break;   
          }
      this.setState({ billItems: list })   
      this.doValidate() 
       }
    calcBill(list, authorisationNumber, authorisationAmount)
        {
            var cashPayable = 0;
            var insurancePayable = 0;
            var shortfallPayable = 0;
            var specialContractPayable = 0;
            for (var index in list)
            {
                if (list[index].activeStatus === "1" || list[index].activeStatus === 1)
                {

                    if (list[index].cashOnly === "1" || list[index].cashOnly === 1)
                    {
                       cashPayable += list[index].totalCost;
                    } else
                    {
                        switch (this.state.billCategory)
                        {
                            case 'CASH':
                                cashPayable += list[index].totalCost;
                                break;
                            case 'INSURANCE':
                                insurancePayable += list[index].totalCost;
                                break;
                            case 'SPECIAL_CONTRACT':
                                specialContractPayable += list[index].totalCost;
                                break;
                                default: 
                                        console.log("....")
                                        break;   
                        }
                    }
                }
            }
            console.log("AUth AMount ....."+authorisationAmount )
            if (authorisationNumber !== null && authorisationNumber !== '' && authorisationAmount > 0)
            {
                cashPayable += Number(insurancePayable) - Number(authorisationAmount);
                insurancePayable = authorisationAmount;
            }
            var cashOwing = Number(cashPayable) - Number(this.state.cashPaid);
            var insuranceOwing = Number(insurancePayable) - Number(this.state.insurancePaid);
            var shortfallOwing = Number(shortfallPayable) - Number(this.state.shortfallPaid);
            var specialContractOwing = Number(specialContractPayable) - Number(this.state.specialContractPaid);
            var totalPayable = Number(cashPayable) + Number(insurancePayable) + Number(shortfallPayable) + Number(specialContractPayable);
            var totalOwing = Number(cashOwing) + Number(insuranceOwing) + Number(shortfallOwing) + Number(specialContractOwing);
            this.setState({cashPayable: cashPayable, insurancePayable: insurancePayable, shortfallPayable: shortfallPayable, specialContractPayable: specialContractPayable, totalPayable: totalPayable
                , cashOwing: cashOwing, insuranceOwing: insuranceOwing, shortfallOwing: shortfallOwing, specialContractOwing: specialContractOwing, totalOwing: totalOwing})
        }
    close() {
        document.getElementById('visitAction').style = 'display:none'
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    calcQuantity(index, value)
    {
        var list = this.state.billItems;  
        list[index].cost = Number(list[index].unitCost) * Number(value);
        list[index].totalCost = list[index].cost;
        this.setState({ billItems: list })  
        this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount);
    }
    getDiagnosisList()
     {
        this.props.setLoader(true)
        this.setState({diagnosisList: [], diagnosis: ''})   
        console.log('..... i am ')
        if(this.state.searchDiagnosis !== null && this.state.searchDiagnosis !== "")
           { console.log('..... i am ')
             axios.get(localStorage.getItem('host') + '/master/diagnosislist?text=' + this.state.searchDiagnosis, {
                 headers: {
                     'Authorization': `Bearer ${localStorage.getItem('token')}`
                 }
             }).then(
                 res => {
                    this.setState({ diagnosisList: res.data });
                     this.props.setLoader(false)
                 }, err => {
                     this.setState({error: err+""})
                    this.props.setLoader(false)
                 }
             )
     
           }
         else{
            this.props.setLoader(false)
         }  
     }
     insertDiagnosis()
      {
        if(this.state.diagnosis !== null && this.state.diagnosis !== '')
        { 
         let list = this.state.patientDiagnosis.slice();
         for (var i in this.state.diagnosisList) {
            if(this.state.diagnosisList[i].code === this.state.diagnosis)
               {
                list.push({ id: 0, code: this.state.diagnosis, description: this.state.diagnosis+" - "+this.state.diagnosisList[i].description, activeStatus: 1 });
               }
        }
         this.setState({ patientDiagnosis: list });
        }

      } 
      deleteDiagnosis(index) {
          let list = this.state.patientDiagnosis.slice();
          list[index].activeStatus = 0
          this.setState({ patientDiagnosis: list });
      }
      save()
        {
            this.props.setLoader(true)
            var billItems = this.state.billItems
            for(var i in this.state.billItems)
               {
                billItems[i].billStartDate = this.processDateTime(billItems[i].billStartDate)
                billItems[i].billEndDate = this.processDateTime(billItems[i].billEndDate)
               }
            var data = {id: this.state.id, billId: this.state.billId, patientId: this.state.patientId, visitOrder: this.state.visitOrder, flagPatient: this.state.flagPatient, billItems: billItems, patientDiagnosis: this.state.patientDiagnosis
                , companyId: this.state.companyId, billCategory: this.state.billCategory, consultantId: this.state.consultantId
                , patientInsuranceId: this.state.patientInsuranceId, specialContractId: this.state.specialContractId, treatmentDateTime: this.state.treatmentDateTime, referringConsultantId: this.state.referringConsultantId.value
                , visitType: this.state.visitType, wardId: this.state.wardId, bedId: this.state.bedId, attendingFromId: this.state.attendingFromId, visitOption: this.state.visitOption, opdPharmacy: this.state.opdPharmacy
                , diagnosisText: this.state.diagnosisText, speciality: this.state.speciality, requestVisitId: this.state.requestVisitId
                , bill: {currencyId: this.state.currencyId, rate: this.state.rate, cashPayable: this.state.cashPayable, insurancePayable: this.state.insurancePayable, shortfallPayable: this.state.shortfallPayable, specialContractPayable: this.state.specialContractPayable, totalPayable: this.state.totalPayable, bookingNumber: this.state.bookingNumber, comment: this.state.comment
                , cashOwing: this.state.cashOwing, insuranceOwing: this.state.insuranceOwing, shortfallOwing: this.state.shortfallOwing, specialContractOwing: this.state.specialContractOwing, totalOwing: this.state.totalOwing, hospitalName: this.state.hospitalName.value, wardName: this.state.wardName, authorisationNumber: this.state.authorisationNumber, authorisationAmount: this.state.authorisationAmount}}
            axios.post(localStorage.getItem('host') + '/patient/savevisit/'+localStorage.getItem('userId'), data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.props.setLoader(false)
                    console.log(res.data)
                    if(res.data.error)
                      {
                        this.setState({ error: res.data.error });  
                      }
                      else
                      {
                       this.getVisit(res.data.id, this.state.patientId, res.data.msg, this.state.requestVisitId);
                      }
                      window.scrollTo(0, 0)
                }, err => {
                     this.setState({error: err+""})
                     window.scrollTo(0, 0)
                    this.props.setLoader(false)
                }
            )   
        }
        hideEntry(index)
         {
            let list = this.state.billItems.slice();
            list[index].activeStatus = 0
            this.setState({ billItems: list }); 
            this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount);
         }    
         claimSubmission(bool, overrideClaim, sendAuthoCode) {
           /* if (bool === 'false')
            {
                document.getElementById("submitHealth263").disabled = true;
            } else
            {
                document.getElementById("reverseHealth263").disabled = true;
            }*/
            this.props.setLoader(true)
            axios.get(localStorage.getItem('host') + '/interchange/sendtohealth263/OUTPATIENT/' + this.state.billId + '/' + this.state.patientInsuranceId + '/' + this.state.companyId + '/' + bool + "/" + overrideClaim + "/" + sendAuthoCode, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
                    .then(res => {
                        if(res.data.error)
                           {
                            this.setState({error: res.data.error})
                            this.updateVisit(this.state.billId)
                           }
                        else
                           {   
                        this.updateVisit(this.state.billId)
                        this.props.getClaimDetails(this.state.billId);
                           }

                        this.props.setLoader(false)
                    })
        }
        processDateTime(date)
          {
            const now = new Date(date);
            const todayY = now.getFullYear();
            const todayM = checkZero(now.getMonth() + 1);
            const todayD = checkZero(now.getDate());
            var date1 = todayY + '-' + checkZero(todayM) + '-' + checkZero(todayD)+ ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());;
            console.log(date1)
           return date1;
          }
          showAddMissingReferring()
          {
           this.setState({doctorName: '', doctorNameError: '', doctorAhfoz: '', doctorAhfozError: '', doctorEmail: '', doctorMobile: '', showReferring: false}) 
           this.validateReferring()
           document.getElementById('missingReferring').style = 'display:block'
          }  
          hideAddMissingReferring()
          {
            document.getElementById('missingReferring').style = 'display:none'
          } 
          doValidateReferring() {
            setTimeout(() => {
                this.validateReferring();
            }, 1000);
        }     
    validateReferring() {

        var error = false;
        if (this.state.doctorName === null) {
            this.setState({ doctorNameError: "Referring Doctor / Unit cannot be empty" });
            error = true
        }
        else if (this.state.doctorName === "") {
            this.setState({ doctorNameError: "Referring Doctor / Unit cannot be empty" });
            error = true
        }
        else if (this.state.doctorName === 0) {
            this.setState({ doctorNameError: "Referring Doctor / Unit cannot be empty" });
            error = true
        }
        else {
            this.setState({ doctorNameError: "" })
        }

        if (this.state.doctorAhfoz === null) {
            this.setState({ doctorAhfozError: "Ahfoz cannot be empty" })
            error = true
        }
        else if (this.state.doctorAhfoz === "") {
            this.setState({ doctorAhfozError: "Ahfoz cannot be empty" })
            error = true
        }
        else {
            this.setState({ doctorAhfozError: "" })
        }
        this.setState({ error: "", showReferring: !error })

    }  
    addNewDoctor()
    {

        this.props.setLoader(true)
        this.setState({ referringConsultants:[]})
        var data = {id: 0, fullname: this.state.doctorName, username: this.state.doctorName, ahfoz: this.state.doctorAhfoz, email: this.state.doctorEmail
            , mobile: this.state.doctorMobile, address: '', activeStatus:1}
        axios.post(localStorage.getItem('host') + '/master/savedoctor', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                var optionsReferringConsultants = [];
                var referringConsultantId = ''
                for(var i in res.data)
                {
                    optionsReferringConsultants.push({value: res.data[i].id, label: res.data[i].name})
                    if(Number(this.state.referringConsultantId) === Number(res.data[i].id))
                       {
                        referringConsultantId = {value: res.data[i].id, label: res.data[i].name}
                       }
                } 
                this.setState({ referringConsultants: res.data, optionsReferringConsultants: optionsReferringConsultants, referringConsultantId: referringConsultantId, msg: 'Successfully Added Referring Doctor / Unit' });  
                this.hideAddMissingReferring()
                window.scrollTo(0, 0)
            }, err => {
                 this.setState({error: err+""})
                 window.scrollTo(0, 0)
                this.props.setLoader(false)
            }
        )   
    }
    openToothinfo(toothInfos, index) {
        console.log(toothInfos)
        document.getElementById('toothInfoAction').style = 'display:block'
        var list =  toothInfos !== null && toothInfos !== ""?JSON.parse(toothInfos):[]
        this.setState({ index: index , toothInfos: list})
    
    }

    saveToothInfo()
      {
      let list = this.state.billItems.slice();
      list[this.state.index].toothInfo = JSON.stringify(this.state.toothInfos)
      this.setState({ billItems: list });
      this.closeToothInfoPopup() 
      }
    closeToothInfoPopup() {
        document.getElementById('toothInfoAction').style = 'display:none'
    }


    addToothInfoTable() {
        let list = this.state.toothInfos.slice();
        list.push({  toothNumber: '', toothSurface: '' });
        this.setState({ toothInfos: list });
    }
    
    setToothInfo(index, name, value) {
        let list = this.state.toothInfos.slice();
        switch (name) {
            case "toothNumber":
                if(/^\d+$/.test(value)){
                    list[index].toothNumber = value
                }
                else{
                    list[index].toothNumber = ''
                }                
                break;
            default:
                list[index].toothSurface = value
                break;
        }
        this.setState({ toothInfos: list });
    }
    
    deleteToothInfo(index)
    {
        let list = this.state.toothInfos.slice();
        list.splice(index, 1); 
        this.setState({ toothInfos: list });  
    }

    showAddMissingHospitalName()
    {
     this.setState({hosName: '', hosNameError: '', showHospitalName: false}) 
     this.validateHospitalName()
     document.getElementById('missingHospitalName').style = 'display:block'
    }  
    hideAddMissingHospitalName()
    {
      document.getElementById('missingHospitalName').style = 'display:none'
    } 
    doValidateHospitalName() {
      setTimeout(() => {
          this.validateHospitalName();
      }, 1000);
  }     
validateHospitalName() {

  var error = false;
  if (this.state.hosName === null) {
      this.setState({ hosNameError: "Hospital Name cannot be empty" });
      error = true
  }
  else if (this.state.hosName === "") {
      this.setState({ hosNameError: "Hospital Name cannot be empty" });
      error = true
  }
  else if (this.state.hosName === 0) {
      this.setState({ hosNameError: "Hospital Name cannot be empty" });
      error = true
  }
  else {
      this.setState({ hosNameError: "" })
  }

  this.setState({ error: "", showHospitalName: !error })

}  
addNewHospitalName()
{
  this.props.setLoader(true)
  var list = this.state.hospitals;
  list.push({value: this.state.hosName, label: this.state.hosName})
  this.setState({ hospitals:list, hospitalName: {value: this.state.hosName, label: this.state.hosName} })
  this.hideAddMissingHospitalName()
  window.scrollTo(0, 0)  
  this.props.setLoader(false)
}
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="visitAction" className="page-popup page-popup-overlay" >
            <div className="card" >
                <div className="card-header  bg-info">Visit Action</div>
                <div className="card-body"  >
                    <div v-if="visit != null">
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">ID No:</span> {this.state.idNumber}</div>
                                    <div className="col"><span className="fw-bold">Cell No:</span> {this.state.mobile}</div>
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                    <div className="col"><span className="fw-bold">Visit(s) Year Count:</span>  {this.state.visitCountInAYear}</div>
                                    <div className="col"><span className="fw-bold">Cimas Claim(s) Year Count:</span>  {this.state.cimasCount}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Last Visit Date:</span> {this.state.lastVisitDate} </div>
                                    <div className="col"><span className="fw-bold">Last Visit Day(s):</span>  {this.state.lastVisitDays}</div>
                                    <div className="col"><span className="fw-bold" style={{color: this.state.zwlStyle}}>ZWL Owing Amount:</span> { this.currencyFormat(this.state.owingAmountZwl)}</div>
                                    <div className="col"><span className="fw-bold" style={{color: this.state.usdStyle}}>USD Owing Amount:</span> { this.currencyFormat(this.state.owingAmountUsd)}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Admission Date:</span> {this.state.billingStartDateTime}</div>
                                    <div className="col"><span className="fw-bold">Discharge Date:</span> {this.state.billingEndDateTime}</div>
                                    <div className="col"></div>
                                    <div className="col"><div className="input-group">
                                                    <span className="input-group-text">Flag Patient</span><input type="color" className="form-control" value={this.state.flagPatient} onChange={e => { this.setState({ flagPatient: e.target.value }) }}  list="presetColors1" />
                                                    <datalist id="presetColors1">
                                                        {this.state.colors.map((item, index) => (
                                                            <option key={index}>{item}</option>
                                                        ))}
                                                    </datalist></div></div>
                                </div>
                               
                            </div>
                        </div>
                        { this.state.investigationRequests.length > 0 &&
                        <div className="card" >
                            <div className="card-header  bg-warning">Investigation Requests</div>
                                <div className="card-body"  >
                                  <div className="row">
                                    <div className="col"> 
                                        <ul>
                                        {this.state.investigationRequests.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                        </ul>
                                    </div>
                                </div>
                              </div>
                            </div>
                           }
                                            <div className="page-popup page-popup-overlay" id="missingReferring" >
                                            <div className="card" >
                                            <div className="card-header  bg-info">Referring Action</div>
                                            <div className="card-body"  >
                                                        <div id="doctorMsg"></div>
                                                        <div className="row">
                                                            <div className="col">
                                                            <div className="input-group has-validation">
                                                                <span className="input-group-text">Referring Doctor / Unit </span><input type="text" className={this.state.doctorNameError !== "" ? "form-control is-invalid" : "form-control"} autoComplete="no" onInput={e => { this.setState({doctorName: e.target.value}); this.doValidateReferring() }} />
                                                                <div className="invalid-feedback">{this.state.doctorNameError}</div>
                                                            </div></div>
                                                            <div className="col">
                                                            <div className="input-group has-validation">
                                                                <span className="input-group-text">Ahfoz Number</span> <input type="text" className={this.state.doctorAhfozError !== "" ? "form-control is-invalid" : "form-control"} autoComplete="no" onInput={e => { this.setState({doctorAhfoz: e.target.value}); this.doValidateReferring() }} />
                                                                <div className="invalid-feedback">{this.state.doctorAhfozError}</div>
                                                            </div></div>
                                                            <div className="col">
                                                            <div className="input-group has-validation">
                                                                <span className="input-group-text">Email</span> <input type="text" className="form-control" autoComplete="no" onInput={e => { this.setState({doctorEmail: e.target.value}); }} />
                                                            </div></div>
                                                            </div>
                                                            <div className="row">
                                                            <div className="col">
                                                            <div className="input-group has-validation">
                                                                <span className="input-group-text">Mobile e.g (263778737373)</span> <input type="text" className="form-control" autoComplete="no" onInput={e => { this.setState({doctorMobile: e.target.value});  }} />
                                                            </div></div>
                                                            </div>
                                                            <div className="row">
                                                            <div className="col" align="center">{ this.state.showReferring === true && (<input type="button" className="btn btn-primary" onClick={() => this.addNewDoctor()} value="Save" />)}</div>
                                                            <div className="col" align="center"> <input type="button" className="btn btn-primary" onClick={() => this.hideAddMissingReferring()} value="Close" /></div>
                                                        </div></div></div>
                                            </div>
                                            <div className="page-popup page-popup-overlay" id="missingHospitalName" >
                                            <div className="card" >
                                            <div className="card-header  bg-info">Hospital Name Action</div>
                                            <div className="card-body"  >
                                                        <div id="doctorMsg"></div>
                                                        <div className="row">
                                                            <div className="col">
                                                            <div className="input-group has-validation">
                                                                <span className="input-group-text">Hospital Name </span><input type="text" className={this.state.hosNameError !== "" ? "form-control is-invalid" : "form-control"} autoComplete="no" onInput={e => { this.setState({hosName: e.target.value}); this.doValidateHospitalName() }} />
                                                                <div className="invalid-feedback">{this.state.hosNameError}</div>
                                                            </div></div>
                                                            <div className="col"></div>
                                                            <div className="col"></div>
                                                            </div>
                                                            <div className="row">
                                                            <div className="col" align="center">{ this.state.showHospitalName === true && (<input type="button" className="btn btn-primary" onClick={() => this.addNewHospitalName()} value="Save" />)}</div>
                                                            <div className="col" align="center"> <input type="button" className="btn btn-primary" onClick={() => this.hideAddMissingHospitalName()} value="Close" /></div>
                                                        </div></div></div>
                                            </div>
                        <br />
                        <div id="visitActionMsg"></div>
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item">
                                <a className={this.state.visitDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("visitDetails") }} href={false} >Visit Details</a>
                            </li>
                            <li className="nav-item">
                                <a className={this.state.billDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("billDetails") }} href={false}>Bill</a>
                            </li>
                            <li className="nav-item">
                                <a className={this.state.diagnosisDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("diagnosisDetails") }} href={false}>Diagnosis</a>
                            </li>
                        </ul>
                        <div >
                            {divMsg}
                            <div className="tab-content pt-2 pl-1" id="pills-tabContent">
                                <div className={this.state.visitDetails ? "tab-pane fade show active" : "tab-pane fade"}>
                                    <div className="card  card-green" >
                                        <div className="card-header">
                                            Visit Details
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Company</span><select className={this.state.companyIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.companyId} onChange={e => { this.setVisit("companyId", e.target.value); this.doValidate() }} >
                                                        <option value="">Choose Company</option>
                                                        {this.state.clusterCompanys.map((item, index) => (
                                                            <option key={index} value={item.id} >{item.companyName}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.companyIdError}</div>
                                                </div></div>
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Bill Category</span><select className={this.state.billCategoryError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.billCategory} onChange={e => { this.setVisit("billCategory", e.target.value); this.doValidate() }} >
                                                        <option value="">Choose Bill Category</option>
                                                        {this.state.billCategorys.map((item, index) => (
                                                            <option key={index} value={item} >{item}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.billCategoryError}</div>
                                                </div></div>
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Consultant</span><select className={this.state.consultantIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.consultantId} onChange={e => { this.setVisit("consultantId", e.target.value); this.doValidate() }}>
                                                        <option value="">Choose Consultant</option>
                                                        {this.state.users.map((item, index) => (
                                                            <option key={index} value={item.id} >{item.name}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.consultantIdError}</div>
                                                </div>
                                                </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">{this.state.billCategory === "INSURANCE" && (<div className="input-group has-validation">
                                                        <span className="input-group-text">{this.state.billCategory}</span><select className={this.state.patientInsuranceIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.patientInsuranceId} onChange={e => { this.setVisit("patientInsuranceId", e.target.value); this.doValidate() }}>
                                                            <option value="0">Choose {this.state.billCategory}</option>
                                                            {this.state.patientInsurances.map((item, index) => (
                                                                <option key={index} value={item.id} >{item.name} - {item.currencyName}</option>
                                                            ))}
                                                        </select><div className="invalid-feedback">{this.state.patientInsuranceIdError}</div></div>) }
                                                        {this.state.billCategory === "SPECIAL_CONTRACT" ? (<div className="input-group has-validation">
                                                            <span className="input-group-text">{this.state.billCategory}</span> <select className={this.state.specialContractIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.specialContractId} onChange={e => { this.setVisit("specialContractId", e.target.value); this.doValidate() }} >
                                                                <option value="0">Choose Special Contract</option>
                                                                {this.state.institutions.map((item, index) => (
                                                                    <option key={index} value={item.id} >{item.name} - {item.currencyName}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback">{this.state.specialContractIdError}</div></div>) : (<span></span>)}
                                                        {this.state.billCategory === "CASH" ? (<div className="input-group has-validation">
                                                            <span className="input-group-text">{this.state.billCategory}</span><select className={this.state.currencyIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.currencyId} onChange={e => { this.setVisit("currencyId", e.target.value); this.doValidate() }}>
                                                                <option value="0">Choose {this.state.billCategory}</option>
                                                                {this.state.currencys.map((item, index) => (
                                                                    <option key={index} value={item.id} >{item.name}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback">{this.state.currencyIdError}</div></div>) : (<span></span>)}
                                                    </div>
                                                    <div className="col" align="right"></div>
                                                    <div className="col" align="right"><div className="input-group"><span className="input-group-text">Treatment Date</span>
                                                        <input type="text" className={this.state.treatmentDateTimeError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.treatmentDateTime} onChange={e => {this.setVisit("treatmentDateTime", e.target.value); this.doValidate()  }} /> 
                                                        <MyDateTimePicker  value={this.state.treatmentDateTime} maxDate={ date1} onChange={(d)=> { this.setVisit("treatmentDateTime", d); this.doValidate()  }} />
                                                        <div className="invalid-feedback">{this.state.treatmentDateTimeError}</div> </div></div>
                                            </div>
                                            {(this.state.speciality === 'RADIOLOGY' || this.state.speciality === 'LABORATORY' || this.state.speciality === 'REHABILITATION' ) && (<div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Referring Doctor</span> 
                                                    <Select
                                                    value={this.state.referringConsultantId}
                                                    className={this.state.referringConsultantIdError !== "" ? "form-control is-invalid" : "form-control"}
                                                    onChange={e =>{ this.setVisit("referringConsultantId", e); this.doValidate() }}
                                                    options={this.state.optionsReferringConsultants}
                                                /><div className="invalid-feedback">{this.state.referringConsultantIdError}</div>
                                                </div>  <input type="button" className="btn btn-success" value="Add Missing Referring" onClick={() => this.showAddMissingReferring()} /></div>
                                            </div>) }
                                            {(this.state.speciality === 'GENERAL_PRACTITIONER' ) && (<div className="row"> 
                                                <div className="col"></div>
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Visit Type</span><select className={this.state.visitTypeError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.visitType} onChange={e => { this.setVisit("visitType", e.target.value); this.doValidate() }}>
                                                        <option value="OUTPATIENT" >OUTPATIENT</option>
                                                        <option value="INPATIENT">INPATIENT</option>
                                                        <option value="PROCEDURE">PROCEDURE</option>
                                                         </select><div className="invalid-feedback">{this.state.visitTypeError}</div>
                                                </div></div>
                                                { this.state.visitType === 'INPATIENT' ?<div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Referring Doctor</span>
                                                    <Select
                                                    value={this.state.referringConsultantId}
                                                    className={this.state.referringConsultantIdError !== "" ? "form-control is-invalid" : "form-control"}
                                                    onChange={e =>{ this.setVisit("referringConsultantId", e); this.doValidate() }}
                                                    options={this.state.optionsReferringConsultants}
                                                /><div className="invalid-feedback">{this.state.referringConsultantIdError}</div>
                                                </div>
                                                    <input type="button" className="btn btn-success" value="Add Missing Referring" onClick={() => this.showAddMissingReferring()} />
                                                   </div>:<div className="col"></div>}
                                            </div>)}
                                            {(  this.state.speciality === 'NEUROLOGY' || this.state.speciality === 'ONCOLOGY' || this.state.speciality === 'PHYSICIAN' || this.state.speciality === 'PAEDIATRICS' || this.state.speciality === 'UROLOGY' || this.state.speciality === 'NEUROSUGERY' || this.state.speciality === 'ORTHOPEDICS' || this.state.speciality === 'GYNAECOLOGY_OBSTRETICS' || this.state.speciality === 'ANAESTHETIST' || this.state.speciality === 'NEPHROLOGIST' || this.state.speciality === 'PAEDIATRICCARDIOLOGIST' || this.state.speciality === 'HAEMATOLOGY') && (<div className="row"> 
                                                <div className="col"></div>
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Visit Type</span><select className={this.state.visitTypeError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.visitType} onChange={e => { this.setVisit("visitType", e.target.value); this.doValidate() }}>
                                                        <option value="OUTPATIENT" >OUTPATIENT</option>
                                                        <option value="INPATIENT">INPATIENT</option>
                                                        <option value="PROCEDURE">PROCEDURE</option>
                                                        {this.state.speciality === 'PAEDIATRICS' || this.state.speciality === 'PHYSICIAN' || this.state.speciality === 'ANAESTHETIST' ? (<option value="VACCINATION">VACCINATION</option>) : (<span></span>)}
                                                    </select><div className="invalid-feedback">{this.state.visitTypeError}</div>
                                                </div></div>
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Referring Doctor</span>
                                                    <Select
                                                    value={this.state.referringConsultantId}
                                                    className={this.state.referringConsultantIdError !== "" ? "form-control is-invalid" : "form-control"}
                                                    onChange={e =>{ this.setVisit("referringConsultantId", e); this.doValidate() }}
                                                    options={this.state.optionsReferringConsultants}
                                                /><div className="invalid-feedback">{this.state.referringConsultantIdError}</div>
                                                </div>
                                                    <input type="button" className="btn btn-success" value="Add Missing Referring" onClick={() => this.showAddMissingReferring()} />
                                                </div>
                                            </div>)}
                                            {this.state.speciality === 'OPD'  && (<div className="row"> 
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Visit Type</span><select className={this.state.visitTypeError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.visitType} onChange={e => { this.setVisit("visitType", e.target.value); this.doValidate() }}>
                                                        <option value="OUTPATIENT" >OUTPATIENT</option>
                                                        <option value="INPATIENT">INPATIENT</option>
                                                        <option value="MATERNITY">MATERNITY</option>
                                                    </select><div className="invalid-feedback">{this.state.visitTypeError}</div>
                                                </div></div>
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Pharmacy Message</span><input type="text" className="form-control" value={this.state.opdPharmacy} onChange={e => { this.setVisit("opdPharmacy", e.target.value); this.doValidate() }} />
                                                </div></div>
                                                <div className="col"></div>                                               
                                            </div>)}
                                            {(this.state.speciality === 'HOSPITAL' || (this.state.speciality === 'OPD' && this.state.visitType === 'INPATIENT')) ? (<div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Ward</span><select className={this.state.wardIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.wardId} onChange={e => { this.setVisit("wardId", e.target.value);  this.doValidate() }}>
                                                        <option value="" >Choose Ward</option>
                                                        {this.state.wards.map((item, index) => (
                                                            <option key={index} value={item.id} >{item.name}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.wardIdError}</div>
                                                </div></div>
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Bed</span><select className={this.state.bedIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.bedId} onChange={e => { this.setVisit("bedId", e.target.value); this.doValidate() }} >
                                                        <option value="" >Choose Bed</option>
                                                        {this.state.beds.map((item, index) => (
                                                            <option key={index} value={item.id} >{item.name}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.bedIdError}</div>
                                                </div></div>
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Referring Doctor</span>
                                                    <Select
                                                    value={this.state.referringConsultantId}
                                                    className={this.state.referringConsultantIdError !== "" ? "form-control is-invalid" : "form-control"}
                                                    onChange={e =>{ this.setVisit("referringConsultantId", e); this.doValidate() }}
                                                    options={this.state.optionsReferringConsultants}
                                                /><div className="invalid-feedback">{this.state.referringConsultantIdError}</div>
                                                </div></div>
                                            </div>) : (<span></span>)}
                                            {this.state.billCategory === "INSURANCE" && this.state.speciality === 'CASUALTY' && (<div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Booking Number</span><input type="text" className={this.state.bookingNumberError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.bookingNumber} onChange={e => { this.setVisit("bookingNumber", e.target.value); this.doValidate() }} /><div className="invalid-feedback">{this.state.bookingNumberError}</div>
                                                </div></div>
                                                <div className="col"></div>
                                                <div className="col"></div>
                                            </div>)}
                                            {this.state.billCategory === "INSURANCE" && this.state.speciality !== 'CASUALTY' && (<div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Booking / Authorisation Number</span><input type="text" className={this.state.bookingNumberError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.bookingNumber} onChange={e => { this.setVisit("bookingNumber", e.target.value); this.doValidate() }} /><div className="invalid-feedback">{this.state.bookingNumberError}</div>
                                                </div></div>
                                                <div className="col"></div>
                                                <div className="col"></div>
                                            </div>)}
                                            {this.state.visitType === "INPATIENT" && (this.state.speciality === 'GENERAL_PRACTITIONER' || this.state.speciality === 'PHYSICIAN' || this.state.speciality === 'PAEDIATRICS' || this.state.speciality === 'UROLOGY' || this.state.speciality === 'NEUROSUGERY' || this.state.speciality === 'ORTHOPEDICS' || this.state.speciality === 'GYNAECOLOGY_OBSTRETICS' || this.state.speciality === 'ANAESTHETIST' || this.state.speciality === 'NEPHROLOGIST' || this.state.speciality === 'PAEDIATRICCARDIOLOGIST' || this.state.speciality === 'ONCOLOGY') && (<div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Hospital Name</span>
                                                    <Select
                                                    value={this.state.hospitalName}
                                                    className={this.state.hospitalNameError !== "" ? "form-control is-invalid" : "form-control"}
                                                    onChange={e =>{ this.setVisit("hospitalName", e); this.doValidate() }}
                                                    options={this.state.hospitals}
                                                    />
                                               <div className="invalid-feedback">{this.state.hospitalNameError}</div>
                                                     </div> <input type="button" className="btn btn-success" value="Add Missing Hospital Name" onClick={() => this.showAddMissingHospitalName()} /></div>
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Ward</span><select className={this.state.wardNameError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.wardName} onChange={e => { this.setVisit("wardName", e.target.value); this.doValidate() }} >
                                                        <option value="">Choose Ward</option>
                                                        <option value="General Ward">General Ward</option>
                                                        <option value="HDU">HDU</option>
                                                        <option value="ICU">ICU</option>
                                                        <option value="ICU">CCU</option>
                                                        <option value="Nursery">Nursery</option>
                                                        </select><div className="invalid-feedback">{this.state.wardNameError}</div>
                                                </div></div>
                                                <div className="col"></div>
                                            </div>)}
                                            {this.state.speciality === 'PAEDIATRICCARDIOLOGIST' && (<div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Attending From</span><select className={this.state.attendingFromIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.attendingFromId} onChange={e => { this.setVisit("attendingFromId", e.target.value); this.doValidate() }}>
                                                        <option value="" >Choose Attending From</option>
                                                        {this.state.attendingFroms.map((item, index) => (
                                                            <option key={index} value={item.id} >{item.name}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.attendingFromIdError}</div>
                                                </div></div>
                                                <div className="col"></div>
                                                <div className="col"></div>
                                            </div>)}
                                            {this.state.visitType === "OUTPATIENT" && this.state.speciality === 'ONCOLOGY' && (<div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Visit Option</span><select className={this.state.visitOptionError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.visitOption} onChange={e => { this.setVisit("visitOption", e.target.value); this.doValidate() }}>
                                                    <option value="" >Choose</option>
                                                        <option value="CONSULTATION" >CONSULTATION</option>
                                                        <option value="REVIEW">REVIEW</option>
                                                        <option value="RADIOTHERAPY">RADIOTHERAPY TREATMENT</option>
                                                           </select><div className="invalid-feedback">{this.state.visitOptionError}</div>
                                                </div></div>
                                                <div className="col"></div>
                                                <div className="col"></div>
                                            </div>)}
                                            {(this.state.speciality === 'GYNAECOLOGY_OBSTRETICS' ) && (<div className="row"> 
                                               <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Visit Order</span><input type="number" className="form-control" value={this.state.visitOrder} onChange={e => { this.setVisit("visitOrder", e.target.value); this.doValidate() }} /><div className="invalid-feedback"></div>
                                                </div></div>
                                                <div className="col"></div>
                                                <div className="col"></div>
                                            </div>)}
                                        </div>
                                    </div>
                                </div>

                                <div className={this.state.billDetails ? "tab-pane fade show active" : "tab-pane fade"}>
                                    {this.state.health263Claim === true && (<div>
                                        <div className="row">
                                            <div className="col"><input type="button" onClick={() => this.props.getMemberLookuPi(this.state.patientId, this.state.billId)} className="btn btn-info" value="Member Lookup" /></div>
                                            <div className="col"><input type="button" onClick={() => this.props.getEnrol(this.state.patientId, this.state.billId)} className="btn btn-info" value="Enrol" /></div>
                                            <div className="col"><input type="button" value="Presign Bio" className="btn btn-info" onClick={() => this.props.getPresignPi(this.state.patientId, this.state.billId)} /></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12" align="center">{this.state.combinedMessages !== '' && (<div className="alert alert-danger">{this.state.combinedMessages}</div>)}  </div>
                                        </div>
                                        <div className="row">
                                            {this.state.canSendToHealth263 && (<div align="center" className="col"><input type="button" id="submitHealth263" className="btn btn-success" onClick={() => this.claimSubmission('false', 'false', 'true')} value="CLAIM WITH BIO" /> </div>)}
                                            {this.state.canSendToHealth263WithOverride && true === false && (<div align="center" className="col"><input type="button" id="submitHealth263" className="btn btn-success" onClick={() => this.claimSubmission('false', 'true', 'false')} value="CLAIM BY-PASS" /> </div>)}
                                            {this.state.canSendToHealth263WithOverride && (<div align="center" className="col"><input type="button" id="submitHealth263" className="btn btn-success" onClick={() => this.claimSubmission('false', 'false', 'false')} value="CLAIM WITH NO BIO" /> </div>)}
                                            {this.state.canCancelHealth263WithBio &&(<div align="center" className="col"><input type="button" id="reverseHealth263" className="btn btn-danger" onClick={() => this.claimSubmission('true', 'false', 'true')} value="REVERSE CLAIM WITH BIO" /></div>)}
                                            {this.state.canCancelHealth263 &&(<div align="center" className="col"><input type="button" id="reverseHealth263" className="btn btn-danger" onClick={() => this.claimSubmission('true', 'false', 'false')} value="REVERSE CLAIM WITH NO BIO" /></div>)}
                                            <div align="center" className="col"><input type="button" id="responseHealth263" className="btn btn-success" onClick={() => this.props.getClaimDetails(this.state.billId)} value="View Claim Response" /></div>
                                        </div>
                                    </div>) }
                                    <br />
                                    <div className="card card-green" >
                                        <div className="card-header">
                                            Bill
                                        </div>
                                        <div className="card-body">
                                        <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Comment</span><textarea className="form-control" data-live-search="true" onChange={e => this.setState({ comment: e.target.value })} value={ this.state.comment}></textarea></div></div>
                                            <div id="tableMsg1"></div>
                                            <div className="row">
                                                <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Service</span>
                                                    <Select
                                                    value={this.state.serviceCostId}
                                                    className="form-control"
                                                    onChange={e => this.setState({ serviceCostId: e, cashOnly: '' })}
                                                    options={this.state.options}
                                                /></div></div>
                                                <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Cash Only Override </span><select className="form-control" data-live-search="true" value={this.state.cashOnly} onChange={e => this.setState({ cashOnly: e.target.value })}>
                                                        <option value=''>Choose to Override</option>
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select></div><div className="alert alert-warning">To be used in cases where services are not billed right by default.</div></div>
                                                <div className="col" align="left"><input type="button" onClick={() => this.addServices()} value="Add" className="btn btn-success" /></div>
                                            </div>
                                            <br />
                                            <div id="toothInfoAction" className="page-popup page-popup-overlay" >
                                                <div className="card" >
                                                    <div className="card-header bg-info">Tooth Number Action</div>
                                                    <div className="card-body" >
                                                        <button onClick={() => this.addToothInfoTable()}><i class="bi bi-plus-square-fill"></i></button>
                                                        <table className="table">
                                                            <tr>
                                                                <th>Tooth Number</th>
                                                                <th>Surface</th>
                                                                <th>Action</th>
                                                            </tr>
                                                            {this.state.toothInfos.map((item, index) => (
                                                                <tr>
                                                                 <td><input type="text" className="form-control" value={item.toothNumber} onChange={e => { this.setToothInfo(index, 'toothNumber', e.target.value); }} /></td>
                                                                 <td><select className="form-control" value={item.toothSurface} onChange={e => { this.setToothInfo(index, 'toothSurface', e.target.value); }} >
                                                            <option value="">Choose Type</option>
                                                            {this.state.toothSurfaces.map((m, index) => (
                                                                <option key={index} value={m.id} >{m.name}</option>
                                                            ))}
                                                        </select></td>
                                                                 <td><span className="link-red" onClick={() => this.deleteToothInfo(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                                </tr>
                                                            ))}
                                                        </table>
                                                        <div className="row">
                                                        <div className="col" align="center" ><button onClick={() => this.saveToothInfo()} className="btn btn-primary">Save</button></div> 
                                                        <div className="col" align="center" ><button onClick={() => this.closeToothInfoPopup()} className="btn btn-primary">Close</button></div> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive-lg"> 
                                            <table className="table table-striped">
                                                <tr>
                                                    <th>Line</th>
                                                    <th>Service</th>
                                                    <th>Tariff Code</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th>Quantity</th>
                                                    <th>Unit Cost</th>
                                                    <th>Cost</th>
                                                   { this.state.speciality === 'DENTAL' && (<th>Tooth Numbers</th>)}
                                                    <th>Autho Amount</th>
                                                    <th>Total Cost</th>
                                                    <th>Cash Only</th>
                                                    <th>Action</th>
                                                </tr>
                                                <tbody>
                                                {this.state.billItems.map((billItem, index) => (
                                                    billItem.activeStatus === 1 &&(<tr key={index}>
                                                        <td >{index + 1}</td>
                                                        <td >{billItem.name}</td>
                                                        <td>{billItem.tariffCode}</td>
                                                        <td> <table><tr><td><input type="text" className="form-control" value={billItem.billStartDate} onChange={e => {this.setBillItem("billStartDate", index, e.target.value ) }} /> </td><td>
                                                        <MyDateTimePicker  value={billItem.billStartDate} maxDate={billItem.billEndDate} onChange={(d)=> {  this.setBillItem("billStartDate", index, d);  }} /></td></tr></table></td>
                                                        <td><table><tr><td> <input type="text" className="form-control" value={billItem.billEndDate} onChange={e => { this.setBillItem("billEndDate", index,  e.target.value ) }} /> </td><td>
                                                        <MyDateTimePicker  value={billItem.billEndDate} minDate={billItem.billStartDate} onChange={(d)=> {  this.setBillItem("billEndDate", index,  d);  }} /></td></tr></table></td>  
                                                        {(billItem.serviceType === 'MULTIPLE_QUANTITY' || billItem.serviceType === 'DATE_RANGE')?(<td><input type="text" className="form-control" value={billItem.quantity} onChange={e => {this.setBillItem("quantity", index, e.target.value); this.calcQuantity(index, e.target.value)}} /></td>):(<td>{billItem.quantity}</td>)}
                                                        {billItem.allowEdit === 1?(<td><div className="input-group-text"><input type="text" className={billItem.unitCostError !== "" ? "form-control is-invalid" : "form-control"} value={billItem.unitCost} onChange={e => this.setBillItem("unitCost", index, e.target.value)} /><div className="invalid-feedback">{billItem.unitCostError}</div></div></td>):(<td>{this.currencyFormat(billItem.unitCost)}</td>)}
                                                        <td>{this.currencyFormat(billItem.cost)}</td>
                                                        { this.state.speciality === 'DENTAL' && (<td>{ billItem.serviceClass === "TOOTH" && (<span>{billItem.toothInfo}<span className="link" onClick={() => this.openToothinfo(billItem.toothInfo, index)} ><i class="bi bi-box-arrow-in-down"></i></span></span>)}</td>)}
                                                        <td><input type="text" className="form-control" value={billItem.authoAmount} onChange={e => this.setBillItem("authoAmount", index, e.target.value)} /></td>
                                                        <td>{this.currencyFormat(billItem.totalCost)}</td>
                                                        <td><select className="form-control" value={billItem.cashOnly} onChange={e => this.setBillItem("cashOnly", index, e.target.value)}>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                            </select></td>
                                                        {billItem.serviceId > 0?(<td><input type="button" className="btn btn-danger" value="Delete" onClick={() => this.hideEntry(index)} /></td>):(<td></td>)}
                                                    </tr>)
                                                ))}
                                                </tbody>
                                            </table>
                                            </div>
                                            <div className="row">
                                            <div className="input-group">
                                                        <input type="hidden" className={this.state.billItemsError !== "" ? "form-control is-invalid" : "form-control"} />
                                                        <div className="invalid-feedback">{this.state.billItemsError}</div> </div></div>
                                                        {this.state.billCategory === "INSURANCE" && (this.state.speciality === 'RENAL' || this.state.speciality === 'HOSPITAL') && (             
                                            <div className="row">
                                                <div className="col"><div className="input-group"><span className="input-group-text">Authorisation Number</span><input type="text" className="form-control" data-live-search="true" onChange={e => {this.setState({ authorisationNumber: e.target.value }); this.calcBill(this.state.billItems, e.target.value, this.state.authorisationAmount);}} value={ this.state.authorisationNumber} /></div></div>
                                                <div className="col"><div className="input-group"><span className="input-group-text">Authorisation Amount</span><input type="text" className="form-control" data-live-search="true" onChange={e => {this.setState({ authorisationAmount: e.target.value }); this.calcBill(this.state.billItems, this.state.authorisationNumber, e.target.value);}} value={ this.state.authorisationAmount} /></div></div>
                                                </div>       )}     
                                             <table className="table table-striped">
                                                <tr>
                                                    <th></th>
                                                    <th>Cash</th>
                                                    <th>Insurance</th>
                                                    <th>Shortfall</th>
                                                    <th>Special Contract</th>
                                                    <th>Total</th>
                                                </tr>
                                                <tbody>
                                                <tr>
                                                    <th>Payable</th>
                                                    <td>{this.currencyFormat(this.state.cashPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.insurancePayable)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.totalPayable)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Paid</th>
                                                    <td>{this.currencyFormat(this.state.cashPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.insurancePaid)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.totalPaid)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Owing</th>
                                                    <td>{this.currencyFormat(this.state.cashOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.insuranceOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.totalOwing)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Refunded</th>
                                                    <td>{this.currencyFormat(this.state.cashRefunded)}</td>
                                                    <td>-</td>
                                                    <td>{this.currencyFormat(this.state.shortfallRefunded)}</td>
                                                    <td>-</td>
                                                    <td>{this.currencyFormat(this.state.totalRefunded)}</td>
                                                </tr></tbody>

                                            </table>
                                        </div>
                                    </div></div>
                                <div className={this.state.diagnosisDetails ? "tab-pane fade show active" : "tab-pane fade"}>
                                    <div className="card card-green">
                                        <div className="card-header  text-dark">
                                            Diagnosis
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xs-12 col-md-12 col-lg-12">
                                                    <a href="http://apps.who.int/classifications/icd10/browse/2010/en#/S31.1" rel="noreferrer" target="_blank">Check Online</a>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col"><div className="input-group">
                                                    <span className="input-group-text">Diagnosis Text</span><textarea className="form-control" value={this.state.diagnosisText} onChange={e => { this.setState({diagnosisText: e.target.value}) }} ></textarea></div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col"><div className="input-group">
                                                    <input type="text" value={this.state.searchDiagnosis} onChange={e => { this.setState({searchDiagnosis: e.target.value}) }} className="form-control" placeholder="Search ICD10 Diagnosis Here" />
                                                    <button className="btn btn-success" onClick={() => this.getDiagnosisList()} ><i class="bi bi-search"></i></button>
                                                </div>
                                                </div>
                                                </div>
                                    { this.state.diagnosisList.length > 0 &&(<div className="row">
                                        <div className="col"><div className="input-group"> 
                                        <select class="form-select" size={this.state.diagnosisList.length}  onChange={e => this.setState({ diagnosis: e.target.value })} aria-label="size 3 select example">
                                        <option value="">Choose Diagnosis</option>
                                      {this.state.diagnosisList.map((item, index) =>(  
  <option key={index} value={item.code}>{item.code} - {item.description}</option>
  ))}
</select><button onClick={() => this.insertDiagnosis()} className="btn btn-success" ><i class="bi bi-plus-circle-fill"></i></button></div></div>
                                    </div> )}
                                            <table className="table">
                                                <tr>
                                                    <th>Description</th>
                                                    <th>Action</th>
                                                </tr>
                                                {this.state.patientDiagnosis.map((item, index) => (item.activeStatus === 1 &&(
                                                    <tr>
                                                        <td>{item.description}</td>
                                                        <td><span className="link-red" onClick={() => this.deleteDiagnosis(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>)))}
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col" align="center">{this.state.showSubmit === true ?(<button onClick={() => this.save()} id="submitButton2" className="btn btn-primary" >Save</button>):(<span></span>)}</div>
                            <div className="col" align="center"><button onClick={() => this.close()} className="btn btn-primary">Close</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)

    }
}      