import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Cancel from "./cancel"
import MyDatePicker from "../mydatepicker";
import MyDateTimePicker from "../mydatetimepicker";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());

var dateTime = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}

export default class QuickBillList extends Component {
    constructor(props) {
        super(props);
        this.cancelRef = React.createRef()
        this.sendCancelRequest = this.sendCancelRequest.bind(this)
        console.log('.......')
    }
    state = {
        number: '', id: 0, firstname: '', surname: '', startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD , companyId: localStorage.getItem('companyId'), list: [], clusterCompanys: [], patientId: 0, reloadPatientList: false, duplicateCount: 0, billingEndDateTime: dateTime, billingEndDateTimeError: ''
        , requestedOnDateTime: dateTime, requestedOnDateTimeError: '', requestReason: '', requestReasonError: '', billOpeningerror: '', billId: 0, msg: '', error: '', status: '', compId: 0
        , billType: 'PROCEDURE', dischargeDateTimeError: '', dischargingSummaryError: '', showSubmit: false, showSubmit1: false, remarksError:'', nurseName: '', bed: '', nurseNotes: '', file: null, submittedDate: ''
    }

    getListLoad() {
        axios.get(localStorage.getItem('host') + '/auth/getclustercompanys/' + localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ clusterCompanys: res.data });
                this.getQuickBillList()
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    getQuickBillList() {
        this.props.setLoader(true)
        this.setState({ list: [], msg: '' , error: '', file: null});
        var data = { firstname: this.state.firstname, surname: this.state.surname, dateRange: this.state.startDate+","+this.state.endDate, companyId: this.state.companyId, billType: this.state.billType }
        axios.post(localStorage.getItem('host') + '/patient/quickbilllist', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    getQuickBillListSilent() {
        this.setState({ list: [], msg: '' , error: '', file: null});
        var data = { firstname: this.state.firstname, surname: this.state.surname, dateRange: this.state.startDate+","+this.state.endDate, companyId: this.state.companyId, billType: this.state.billType }
        axios.post(localStorage.getItem('host') + '/patient/quickbilllist', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                console.log("silent list []: "+JSON.stringify(res.data))
            }, err => {
                this.setState({ error: err + "" })
            }
        )
    }
    setRecordType(index, e) {
        let list = this.state.patientRecords.slice();
        list[index].recordType = e.target.value;
        this.setState({ patientRecords: list })
    }
    close() {
        document.getElementById('quickBillListAction').style = 'display:none'
    } // v-bind:style="'height: '+heightQuickBillList+'px;max-height: '+heightQuickBillList+'px;overflow: auto;overflow-x: hidden;'"
    closeBill(billNumber, billId) {
       this.setState({number: billNumber, billId: billId})
       document.getElementById('billCloseQBAction').style = 'display:block' 
    }
    closeBillClosePopup()
     {
        document.getElementById('billCloseQBAction').style = 'display:none' 
     }
    sendBillClose() {
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patient/closebill/' + this.state.billId+'/'+this.state.billingEndDateTime, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ msg: res.data });
                this.props.setLoader(false)
                this.closeBillClosePopup()
                this.getQuickBillList()
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    sendCancelRequest(billId, reason) {
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patient/cancelbill/' + billId+"?reason="+reason, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ msg: res.data });
                document.getElementById('cancelAction').style = 'display:none'
                this.getQuickBillList()
            }, err => {
                this.setState({ error: err + "" })
                document.getElementById('cancelAction').style = 'display:none'
                this.props.setLoader(false)
            }
        )
    }
    doValidate() {
        setTimeout(() => {
            this.validateOpeningRequest();
        }, 1000);
    }

    validateOpeningRequest() {

        var error = false;
        if (this.state.requestReason === null) {
            this.setState({ requestReasonError: "Reason cannot be empty" });
            error = true
        }
        else if (this.state.requestReason === "") {
            this.setState({ requestReasonError: "Reason cannot be empty" });
            error = true
        }
        else {
            this.setState({ requestReasonError: "" })
        }

        if (this.state.requestedOnDateTime === null) {
            this.setState({ requestedOnDateTimeError: "Request Date cannot be empty" })
            error = true
        }
        else if (this.state.requestedOnDateTime === "") {
            this.setState({ requestedOnDateTimeError: "Request Date cannot be empty" })
            error = true
        }
        else if (!(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):\d{2}:\d{2}$/.test(this.state.requestedOnDateTime))) {
            this.setState({ requestedOnDateTimeError: "Request Date wrong format" })
            error = true
        }
        else {
            this.setState({ requestedOnDateTimeError: "" })
        }

        if (error === true) {
            this.setState({ billOpeningerror: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
        }
        else {
            this.setState({ billOpeningerror: "", showSubmit: true })
        }
    }

    processDateTime(date) {
        console.log(date)
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        const todayH = checkZero(now.getHours());
        const todayMM = checkZero(now.getMinutes());
        const todayS = checkZero(now.getSeconds());
        var date1 = todayY + '-' + todayM + '-' + todayD + ' ' + todayH + ':' + todayMM + ':' + todayS;
        console.log(date1)
        return date1;
    }
    doValidate1() {
        setTimeout(() => {
            this.validateDischarge();
        }, 1000);
    }

    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
       return date1;
      }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        let divBillOpeningMsg = ''
        if (this.state.billOpeningerror) {
            divBillOpeningMsg = (<div className='alert alert-danger'>{this.state.billOpeningerror}</div>)
        }

        let divDischargingSummaryMsg = ''
        if (this.state.dischargingSummaryError) {
            divDischargingSummaryMsg = (<div className='alert alert-danger'>{this.state.dischargingSummaryError}</div>)
        }
        return (
            <div id="quickBillListAction" className="page-popup page-popup-overlay"   >
                <div className="card" >
                    <div className="card-header bg-info">Quick Bill List</div>
                    <div className="card-body" >
                    <div id="billCloseQBAction" className="page-popup page-popup-overlay" >
                        <div className="card" >
                            <div className="card-header bg-info">Bill Close Action</div>
                            <div className="card-body" >
                            <p>Are you sure you want to Close Bill  {this.state.number}?</p> 
                            <div className="row">
                            <div className="col flex-container"><div className="input-group"><span className="input-group-text datepicker__title">Close Date</span>
                                                        <input type="text" className={this.state.billingEndDateTimeError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.billingEndDateTime} onChange={e => { this.setState({ billingEndDateTime: e.target.value }) }} /> 
                                                                                             <MyDateTimePicker  value={this.state.billingEndDateTime} maxDate={dateTime} onChange={(d)=> {  this.setState({billingEndDateTime: d}); }} />
                                                        <div className="invalid-feedback">{this.state.billingEndDateTimeError}</div> </div></div>
                                                        <div className="col"></div>
                            </div>
                                <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.sendBillClose()} className="btn btn-primary">Yes</button></div> 
                                <div className="col" align="center" ><button onClick={() => this.closeBillClosePopup()} className="btn btn-primary">No</button></div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="signedClaimFileAction" className="page-popup page-popup-overlay" >
                        <div className="card" >
                            <div className="card-header bg-info">Upload Physical Claim Form File Action</div>
                            <div className="card-body" >
                            <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Upload Signed Claim Form [15MB]</span> <input type="file"  accept=".pdf" onChange={e => {this.setState({file :e.target.files[0]}); this.doValidate();}} className="form-control" /></div></div>
                                            <div className="col"></div>
                                        </div>
                                <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.sendPhysicalClaimFile()} className="btn btn-primary">Save</button></div> 
                                <div className="col" align="center" ><button onClick={() => this.closePhysicalClaimFilePopup()} className="btn btn-primary">Close</button></div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="updateClaimStatusAction" className="page-popup page-popup-overlay" >
                        <div className="card" >
                            <div className="card-header bg-info">Update Claim Status Action</div>
                            <div className="card-body" >
                            <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Submitted Date</span>
                                    <input type="text" className="form-control" value={this.state.submittedDate} onChange={e => this.setState({ submittedDate: e.target.value })} />
                                    <MyDatePicker onChange={(d)=> { this.setState({submittedDate: d}) }} />
                                    </div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Status</span>
                                        <div ><select className="form-control" value={this.state.status} onChange={e => this.setState({ status: e.target.value })} >
                                            <option value="">Choose</option>
                                            <option value="PENDING">PENDING</option>
                                            <option value="NON_HEALTH263_CLAIM">NON_HEALTH263_CLAIM</option>
                                            <option value="MANUALLY_SUBMITTED">MANUALLY_SUBMITTED</option>
                                            <option value="REJECTED_CLAIM">REJECTED_CLAIM</option>
                                            <option value="RESUBMISSION_REQUIRED">RESUBMISSION_REQUIRED</option>
                                            </select></div>
                                    </div></div>
                                </div>
                                <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.sendClaimStatus()} className="btn btn-primary">Save</button></div> 
                                <div className="col" align="center" ><button onClick={() => this.closeClaimStatusPopup()} className="btn btn-primary">Close</button></div> 
                                </div>
                            </div>
                        </div>
                    </div>
                        {divMsg}
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Firstname </span> <input type="text" className="form-control" onChange={(e) => this.setState({ firstname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Surname</span><input type="text" className="form-control" onChange={(e) => this.setState({ surname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span>  <input type="text" className="form-control" value={this.state.startDate} onChange={e => { this.setState({ startDate: e.target.value }) }} /> 
                                                                                 <MyDatePicker  value={this.state.startDate} maxDate={this.state.endDate} onChange={(d)=> {  this.setState({startDate: d});  }} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">End Date</span>  <input type="text" className="form-control" value={this.state.endDate} onChange={e => { this.setState({ endDate: e.target.value }) }} /> 
                                                                                 <MyDatePicker  value={this.state.endDate} minDate={this.state.startDate} onChange={(d)=> {  this.setState({endDate: d});  }} /></div></div>
                                             <div className="col"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" value={this.state.companyId} onChange={(e) => this.setState({ companyId: e.target.value })}  >
                                                {this.state.clusterCompanys.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select>  </div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Type</span>
                                        <div ><select className="form-control" value={this.state.billType} onChange={e => this.setState({ billType: e.target.value })} >
                                            <option value="PROCEDURE" >PROCEDURE</option>
                                            <option value="VACCINE">VACCINE</option>
                                            </select></div>
                                    </div></div>
                                            <div className="col">    <button className="btn btn-success" onClick={() => this.getQuickBillList()} ><i class="bi bi-search"></i></button></div>
                                        </div></div></div>
                            </div>
                        </div>
                        <div className="table-responsive-lg"> 
                            <table className="table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th>Action</th>
                                    <th>Number</th>
                                    <th>Claim Ref</th>
                                    <th>Name</th>
                                    <th>Bill Category</th>
                                    <th>Date of Birth</th>
                                    <th>Gender</th>
                                    <th>Date</th>
                                    <th>Consultant</th>
                                    <th>Bill Status</th>
                                    <th>Type</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {this.state.list.map((bill, index) => (
                                        <tr key={index} className={bill.color}>
                                            {bill.billStatus !== 'CANCELLED' ? (<td >
                                               <span className="link" onClick={() => this.props.getPrintOut(bill.id, 'BILLING', [], bill.companyId)} title="Print" ><i className="bi bi-printer-fill"></i></span>
                                                {bill.receiptOwingAmount > 0 ? (<span className="link" onClick={() => this.props.openReceiptAction(bill.id, bill.billType, 'VISIT')} title="Receipt Bill"><i className="bi bi-clipboard-check-fill"></i></span>) : (<span></span>)}
                                                {bill.billStatus === 'CREATED' ? (<span className="link" onClick={() => this.closeBill(bill.number, bill.id)} title="Close Bill"><i className="bi bi-file-earmark-lock2-fill"></i></span>) : (<span></span>)}
                                                {bill.billStatus === 'CREATED' ? (<span className="link-red" onClick={() => this.cancelRef.current.cancel(bill.number, bill.id)} title="Cancel Visit"><i className="bi bi-x-square-fill"></i></span>) : (<span></span>)}
                                                   <span className="link" onClick={() => this.props.openPatient(bill.patientId)} title="Edit Patient"><i class="bi bi-check2-circle"></i></span>
                                             </td>) : (<td ></td>)}
                                            <td>{bill.number}</td>
                                            <td>{bill.ref}</td>
                                            <td>{bill.fullname}</td>
                                            <td>{bill.billCategory}</td>
                                            <td>{bill.dateOfBirth}</td>
                                            <td>{bill.gender}</td>
                                            <td>{bill.treatmentDateTime}</td>
                                            <td>{bill.consultant}</td>
                                            <td>{bill.billStatus}</td>
                                            <td>{bill.billType}</td>
                                               </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="row">
                            <div className="col-lg-12" align="center"><button onClick={() => this.close()} className="btn btn-primary">Close Window (Esc)</button></div>
                        </div>
                        <div id="openingRequestAction" className="page-popup page-popup-overlay"><div className="card" >
                            <div className="card-header bg-info">{this.state.billId > 0?'BIll': 'Visit'} Opening Request Action</div>
                            <div className="card-body" >
                                {divBillOpeningMsg}
                                <div className="row">
                                     <div className="col">
                                        <div className="input-group  has-validation"><span className="input-group-text">Request Date </span>
                                        <input type="text" className={this.state.requestedOnDateTimeError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.requestedOnDateTime} onChange={e => { this.setState({ requestedOnDateTime: e.target.value }) }} /> 
                                        <MyDateTimePicker  value={this.state.requestedOnDateTime} maxDate={dateTime} onChange={(d)=> {  this.setState({requestedOnDateTime: d}); }} />
                                           <div className="invalid-feedback">{this.state.requestedOnDateTimeError}</div></div>
                                    </div>
                                    <div className="col"><div className="input-group has-validation"><span className="input-group-text">Reason </span> <textarea className={this.state.requestReasonError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.requestReason} onChange={e => { this.setState({ requestReason: e.target.value }); this.doValidate() }}></textarea> <div className="invalid-feedback">{this.state.requestReasonError}</div></div></div>
                                   
                                </div>
                                <div className="row">
                                    <div className="col-lg-6" align="right" >{this.state.showSubmit === true ? (<input className="btn btn-primary" type='button' onClick={() => this.saveOpeningRequestAction()} value="Save" />) : (<span></span>)}</div>
                                    <div className="col-lg-6" align="left" ><input className="btn btn-primary" type='button' onClick={() => this.closeOpeningRequest()} value="Close" /></div>
                                </div></div></div>
                        </div>
                        <div id="dischargingSummaryAction" className="page-popup page-popup-overlay"><div className="card" >
                            <div className="card-header bg-info">Discharge Summary Action</div>
                            <div className="card-body" >
                            {divDischargingSummaryMsg}
                                <div className="row">         
                                    <div className="col">
                                        <div className="input-group  has-validation"><span className="input-group-text">Discharge Date </span>
                                            <input type="text" className={this.state.dischargeDateTimeError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.dischargeDateTime} onChange={e => { this.setState({ dischargeDateTime: e.target.value }) }} /> 
                                            <MyDateTimePicker  value={this.state.dischargeDateTime} maxDate={dateTime} onChange={(d)=> {  this.setState({dischargeDateTime: d}); }} />
                                                <div className="invalid-feedback">{this.state.dischargeDateTimeError}</div></div>
                                    </div>
                                            <div className="col"><div className="input-group has-validation"><span className="input-group-text">Remarks </span> <textarea className={this.state.remarksError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.remarks} onChange={e => {this.setState({ remarks: e.target.value }); this.doValidate()}}></textarea> <div className="invalid-feedback">{this.state.remarksError}</div></div></div>

                                </div>
                                <div className="row">
                                    <div className="col-lg-6" align="right" >{this.state.showSubmit1 === true ? (<input className="btn btn-primary" type='button' onClick={() => this.saveDischarge()} value="Save" />) : (<span></span>)}</div>
                                    <div className="col-lg-6" align="left" ><input className="btn btn-primary" type='button' onClick={() => this.closeDischarge()} value="Close" /></div>
                                </div></div></div>
                        </div>
                        <div id="nurseSummaryAction" className="page-popup page-popup-overlay"><div className="card" >
                            <div className="card-header bg-info">Nurse Summary Action</div>
                            <div className="card-body" >
                                <div className="row">
                                <div className="col"><span className="fw-bold">Attending Nurse:</span> {this.state.nurseName}</div>
                                    <div className="col"><span className="fw-bold">Bed Details:</span> {this.state.bed}</div>
                                    <div className="col"><span className="fw-bold">Notes:</span> {this.state.nurseNotes}</div>
                                </div>
                                <div className="row">
                                    <div className="col" align="center" ><input className="btn btn-primary" type='button' onClick={() => this.closeNurseSummary()} value="Close" /></div>
                                </div></div></div>
                        </div>
                        <Cancel ref={this.cancelRef} sendCancelRequest={this.sendCancelRequest} />
                    </div>
                </div>
            </div>
        )

    }
}      